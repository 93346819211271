import { createApi } from '@reduxjs/toolkit/query/react';
import { baseApi } from './baseApi';
import { CREATE_FLIGHT_ORDER, SMB_ANCILLARY } from '../store/apiUrls';

export const createOrderApi = createApi({
  reducerPath: 'createOrderApi',
  baseQuery: baseApi,
  endpoints: (builder) => ({
    createOrder: builder.mutation({
      query: (body) => ({ url: CREATE_FLIGHT_ORDER, method: 'POST', body }),
    }),
    getSMB: builder.query({
      query: (ancillaryId) => `${SMB_ANCILLARY}/${ancillaryId}`,
    }),
    updateSMB: builder.mutation({
      query: (body) => ({
        url: `${SMB_ANCILLARY}/${body.ancillaryId}`,
        method: 'PUT',
        body,
      }),
    })
  }),
});

export const { useCreateOrderMutation, useGetSMBQuery, useUpdateSMBMutation } = createOrderApi;
