import { FC, useState } from 'react';
import { Box, Container, Divider, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { OAFareSummary, OAImage, OASwipeableDrawer } from '../../../components';
import { formatToINR } from '../../../utils';
import { useSelector } from '../../../store';
import { getFeatureFlag } from '../../../store/slices/featuresSlice';

interface PaymentAndInvoiceProps {
  order: any;
  isLoading: boolean;
}

const PaymentAndInvoice: FC<PaymentAndInvoiceProps> = ({ order, isLoading }) => {
  const { esimOffer } = useSelector(getFeatureFlag);

  const orderAmount = order?.orderAmount;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toggleDrawer = () => setIsOpen((prev) => !prev);

  return (
    <>
      <Container>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb="8px">
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            Total paid
          </Typography>
          <Typography variant="h2">
            {orderAmount?.paymentSummary?.amountInCash
              ? formatToINR(orderAmount?.paymentSummary?.amountInCash)
              : '0'}
          </Typography>
        </Box>
        {orderAmount?.paymentSummary?.amountInCoins > 0 ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              bgcolor: 'primary.light',
              p: '6px 10px',
              borderRadius: '4px',
              my: '8px',
            }}
          >
            <Box display="flex" alignItems="center">
              <Typography variant="body3" sx={{ fontWeight: 600, mr: '4px' }}>
                {formatToINR(orderAmount?.paymentSummary?.amountInCoins)}
              </Typography>
              <Typography variant="body3">saved on this trip</Typography>
            </Box>

            <Box display="flex" alignItems="center">
              <Typography variant="body3" sx={{ mr: '4px' }}>
                using
              </Typography>
              <Typography variant="body3" sx={{ fontWeight: 600 }}>
                {formatToINR(orderAmount?.paymentSummary?.coinsRedeemed, false)}
              </Typography>
              <OAImage
                src="3xNiyo_coin.png"
                alt="coins"
                sx={{ mx: '5px', height: '18px', width: '18px' }}
              />
            </Box>
          </Box>
        ) : null}

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          py="8px"
          onClick={toggleDrawer}
        >
          <Typography variant="body2">View payment details</Typography>
          <ChevronRightIcon fontSize="small" sx={{ color: 'secondary.main' }} />
        </Box>
        {/* <Box display="flex" alignItems="center" justifyContent="space-between" py="8px">
          <Typography variant="body2">Download invoice</Typography>
          <ChevronRightIcon fontSize="small" sx={{ color: 'secondary.main', fontWeight: 300 }} />
        </Box> */}
      </Container>
      {order?.international && esimOffer && <Box px='16px' mt='20px'>
        <OAImage src="esim.svg" folder="offer" alt="esim" width="100%" />
      </Box>}
      <Divider sx={{ borderBottomWidth: '4px', borderColor: '#F4F6F5', my: '20px' }} />
      <OASwipeableDrawer
        title="Payment details"
        open={isOpen}
        onOpen={toggleDrawer}
        onClose={toggleDrawer}
        isContainer={false}
      >
        <OAFareSummary data={order} />
      </OASwipeableDrawer>
    </>
  );
};

export default PaymentAndInvoice;
