import { Box, Typography } from "@mui/material";
import { OAImage } from "./OAImage";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const OAFlightRoute = ({from, to, icon}: { from: string, to: string, icon: any }) => {
  return (
    <Box display="flex" alignItems="center" color='text.secondary'>
      <OAImage src={icon} folder="icons" alt="flight" sx={{ ml: '4px', height: '18px', width: '18px', color: '#5A6068' }} />
      <Typography variant="body3" fontWeight={600} ml="6px">
        {from}
      </Typography>
      <ArrowForwardIcon sx={{ ml: '4px', height: '18px', width: '18px', color: '#5A6068' }} />
      <Typography variant="body3" fontWeight={600} ml="4px">
        {to}
      </Typography>
    </Box>
  )
}

export default OAFlightRoute;