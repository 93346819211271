import { useCallback, useEffect } from 'react';
import { TabsList, Tab, TabPanel } from './StyledComponents';
import { Tabs } from '@mui/base/Tabs';
import SeatPanel from './SeatPanel';
import MealsPanel from './MealsPanel';
import BaggagePanel from './BaggagePanel';
import { Box, Skeleton } from '@mui/material';
import OAChip from '../OAChip';
import TripChip from '../TripChip';

interface OATabsProps {
  isLoading: boolean;
  tabValue: number;
  setTabValue: (value: number) => void;
  smbData: any;
  handleTabChange: (event: any, newValue: any) => void;
  updateSmbAction: any;
  totalTravellers: number;
  selectedSmbData: any;
  selectedSegment: { from: string; to: string };
  setSelectedSegment: any;
  segmentBasedTabs: any;
}

export default function OATabs({
  isLoading,
  tabValue,
  setTabValue,
  smbData,
  handleTabChange,
  updateSmbAction,
  totalTravellers,
  selectedSmbData,
  selectedSegment,
  setSelectedSegment,
  segmentBasedTabs,
}: OATabsProps) {
  const handleSelectSegment = useCallback((data: any) => {
    setSelectedSegment(data);
  }, []);

  useEffect(() => {
    setSelectedSegment({
      from: segmentBasedTabs?.[0]?.from || '',
      to: segmentBasedTabs?.[0]?.to || '',
    });
  }, [segmentBasedTabs]);

  return (
    <Box bgcolor="#F4F6F5">
      <Tabs value={tabValue} onChange={handleTabChange}>
        <Box position="sticky" top="90px" bgcolor="white" zIndex={10} paddingBottom="10px" px="16px">
          <TabsList>
            <Tab value={1}>Seat</Tab>
            <Tab value={2}>Meals</Tab>
            <Tab value={3}>Baggage</Tab>
          </TabsList>
          <Box
            display="flex"
            sx={{
              overflowX: 'scroll',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
              '-ms-overflow-style': 'none',
              'scrollbar-width': 'none',
            }}
          >
            {segmentBasedTabs?.map((segment: any) => (
              <OAChip
                key={`${segment.from}-${segment.to}`}
                label={<TripChip from={segment.from} to={segment.to} airline={segment?.segmentsInfo?.airline?.code} />}
                onClick={() => handleSelectSegment(segment)}
                sx={selectedSegment.from === segment.from && selectedSegment.to === segment.to ? { bgcolor: '#D9FCF3 !important' } : {}}
                selected={selectedSegment.from === segment.from && selectedSegment.to === segment.to}
              />
            ))}
          </Box>
        </Box>
        <TabPanel value={1}>
          <SeatPanel
            seatData={smbData}
            selectedSegment={selectedSegment}
            totalTravellers={totalTravellers}
            updateSmbAction={updateSmbAction}
            selectedSmbData={selectedSmbData}
          />
        </TabPanel>
        <TabPanel value={2}>
          <MealsPanel
            mealsData={smbData}
            selectedSegment={selectedSegment}
            updateSmbAction={updateSmbAction}
            totalTravellers={totalTravellers}
          />
        </TabPanel>
        <TabPanel value={3}>
          <BaggagePanel
            baggageData={smbData}
            selectedSegment={selectedSegment}
            updateSmbAction={updateSmbAction}
            totalTravellers={totalTravellers}
          />
        </TabPanel>
      </Tabs>
    </Box>
  );
}
