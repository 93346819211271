import * as Sentry from "@sentry/react";
interface ErrorContext {
  [key: string]: unknown;
}
const isNetworkError = (error: any): boolean => {
  if (error instanceof TypeError) {
    return error.message.includes('NetworkError') || error.message.includes('Failed to fetch');
  }
  return false;
};
const logError = (error: any, context: ErrorContext = {}): void => {
  console.error('API Error:', error, context);
  // Don't send network errors to Sentry
  if (isNetworkError(error)) {
    console.log('Network error detected, not sending to Sentry:', error);
    return;
  }
  // Log to Sentry
  Sentry.withScope((scope: Sentry.Scope) => {
    // Set the error context
    Object.entries(context).forEach(([key, value]) => {
      scope.setExtra(key, value);
    });
    // Capture exception
    if (error instanceof Error) {
      Sentry.captureException(error);
    } else {
      Sentry.captureMessage(JSON.stringify(error));
    }
  });
};
export default logError;