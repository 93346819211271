import { Box, Grid, Typography } from '@mui/material';
import { formatISO, isBefore, isSameDay, isWithinInterval, startOfDay } from 'date-fns';
import { useCallback, useEffect, useMemo } from 'react';
import { DayPicker, DayProps } from 'react-day-picker';

const DayNameBoxesGrid = () => (
  <Grid container spacing={0}>
    {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((day, index) => (
      <Grid item xs={12 / 7} key={index} textAlign="center">
        <Typography
          variant="body3"
          sx={{
            textAlign: 'center',
            color: ['Sat', 'Sun'].includes(day) ? 'error.main' : 'grey.800',
            fontFamily: 'Inter, sans-serif, Arial',
          }}
        >
          {day}
        </Typography>
      </Grid>
    ))}
  </Grid>
);

const DayHeader = () => {
  const MemoizedDayNameBoxesGrid = useMemo(() => <DayNameBoxesGrid />, []);
  return (
    <Box
      sx={{
        width: '100%',
        position: 'sticky',
        top: '90px',
        zIndex: 10,
        backgroundColor: '#fff',
        py: '16px',
        borderBottom: '1px solid #E6E6E6',
      }}
    >
      {MemoizedDayNameBoxesGrid}
    </Box>
  );
};

interface OADatePickerProps {
  mode: 'single' | 'range';
  onDateSelect: (range: any) => void;
  selected?: any;
  prices?: any;
}

export const OADayPicker = ({ mode = 'single', onDateSelect, selected, prices }: OADatePickerProps) => {
  const formattedPrices = useMemo(() => {
    if (prices) {
      return prices?.reduce((acc: any, { date, priceItem }: any) => {
        const formattedDate = formatISO(new Date(date), { representation: 'date' });
        acc[formattedDate] = {
          display: mode === 'single' ? priceItem?.onwardPrice?.display : (priceItem?.returnPrice?.display ?? priceItem?.onwardPrice?.display),
          color: mode === 'single' ? priceItem?.onwardPrice?.tag?.color : (priceItem?.returnPrice?.tag?.color ?? priceItem?.onwardPrice?.tag?.color),
        };
        return acc;
      }, {});
    }
    return {};
  }, [prices, mode]);

  const willScroll = mode && selected;

  useEffect(() => {
    const selectedDayElement = document.querySelector('.rdp-day_selected');
    if (selectedDayElement) {
      selectedDayElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      });
    }
  }, [willScroll]);

  const handleDayClick = useCallback(
    (day: any) => {
      if (mode === 'range') {
        if (!selected || !('from' in selected) || selected?.to) {
          onDateSelect({ from: day, to: undefined });
        } else {
          if (selected?.from && isBefore(day, selected?.from)) {
            onDateSelect({ from: day, to: selected?.to });
          } else {
            onDateSelect({ from: selected?.from, to: day });
          }
        }
      } else {
        onDateSelect({ from: day });
      }
    },
    [mode, onDateSelect, selected]
  );

  const renderDay = useCallback(
    (dayProps: DayProps) => {
      const { date, displayMonth }: any = dayProps;
      const currentDate = new Date(date);
      const displayMonthDate = new Date(displayMonth);
      const dateString = formatISO(currentDate, { representation: 'date' });
      const isPast = isBefore(currentDate, startOfDay(new Date()));

      const price = formattedPrices[dateString] ?? { display: isPast ? ' ' : '-' };
      const isOutside = currentDate.getMonth() !== displayMonthDate.getMonth();

      let isSelected = false;
      let isRangeStart = false;
      let isRangeEnd = false;
      let isMiddle = false;

      if (selected) {
        if (mode === 'single' && (isSameDay(currentDate, new Date(selected)) || isSameDay(currentDate, new Date(selected?.from)))) {
          isSelected = true;
        } else if (mode === 'range' && 'from' in selected && selected?.from) {
          isRangeStart = selected?.from && isSameDay(currentDate, new Date(selected?.from));
          isRangeEnd = selected?.to && isSameDay(currentDate, new Date(selected?.to));
          isMiddle = selected?.from && selected?.to && isWithinInterval(currentDate, { start: new Date(selected?.from), end: new Date(selected?.to) }) && !isRangeStart && !isRangeEnd;
          isSelected = isRangeStart || isRangeEnd || isMiddle;
        }
      }

      const dayCellStyles = {
        width: '60px',
        height: '60px',
        background: (mode === 'single' && isSelected && !isOutside) ? '#00c6a1' :
          ((isRangeStart || isRangeEnd) && isSelected && !isOutside) ? '#00c6a1' :
            (isMiddle && !isOutside) ? '#f4f6f5' : '#fff',
        color: (isPast && !isOutside) ? 'rgba(0,0,0, 0.3)' : (mode === 'single' && isSelected && !isOutside) ? 'white' :
          ((isRangeStart || isRangeEnd) && !isOutside) ? 'white' :
            (isMiddle && !isOutside && isSelected) ? '#0a1017' : 'black',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '2px',
        border: 'none',
        borderRadius: '20px',
        cursor: isOutside ? 'default' : 'pointer',
      };

      return (
        <Box
          component="button"
          sx={dayCellStyles}
          className={isSelected && !isOutside ? 'rdp-day_selected' : ''}
          onClick={() => !isOutside && !isPast && handleDayClick(date)}
        >
          <Typography color={(isOutside ? 'white' : (isPast && !isOutside) ? 'rgba(0,0,0, 0.3)' : (mode === 'single' && isSelected && !isOutside) ? 'white' :
            ((isRangeStart || isRangeEnd) && !isOutside) ? 'white' :
              (isMiddle && !isOutside && isSelected) ? '#0a1017' : 'black')}>
            {date.getDate()}
          </Typography>
          <Typography
            variant="body4"
            mt="2px"
            color={isOutside ? 'white' : (mode === 'single' && isSelected && !isOutside) ? 'white' : (isRangeStart || isRangeEnd) && !isOutside ? 'white' : (isMiddle && !isOutside && isSelected) ? '#0a1017' : price?.color ?? 'black'}
          >
            {price?.display}
          </Typography>
        </Box>
      );
    },
    [formattedPrices, handleDayClick, mode, selected]
  );

  const dayPickerProps: any = useMemo(
    () => ({
      mode,
      numberOfMonths: 12,
      disabled: (date: any) => isBefore(date, startOfDay(new Date())),
      className: 'oa-day-picker',
      disableNavigation: true,
      selected,
      onSelect: handleDayClick,
      weekStartsOn: 1,
      hideHead: true,
      components: {
        Day: renderDay,
      },
    }),
    [mode, selected, handleDayClick, renderDay]
  );

  return (
    <>
      <DayHeader />
      <DayPicker {...dayPickerProps} />
    </>
  );
};
