import { ChangeEvent, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { OAImage, OASwitch } from '.';
import { useDispatch, useSelector } from 'react-redux';
import { getUserInputState, setIsCoinsApplied } from '../store/slices/userInputSlice';
import { getUserInfo } from '../store/slices/userInfoSlice';
import { formatToINR, getBurnCoin } from '../utils';
import { getFeatureFlag } from '../store/slices/featuresSlice';
import { eventsTracker } from '../utils/ctEventsTracking';
import { usePostHog } from 'posthog-js/react';
import { getAuthInfo } from '../store/slices/authSlice';

export const OACoinSwitch = ({ isReviewPage = false }: { isReviewPage?: boolean }) => {
  const posthog = usePostHog();
  const dispatch = useDispatch();
  const { isCoinsApplied } = useSelector(getUserInputState);
  const { coins } = useSelector(getUserInfo);
  const { burnCoinsFeat } = useSelector(getFeatureFlag);
  const { campaigns } = useSelector(getAuthInfo);
  const [startTime, setStartTime] = useState(Date.now());

  useEffect(() => {
    setStartTime(Date.now());
  }, []);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);

    if (event.target.checked) {
      eventsTracker(
        {
          flowName: 'Flight',
          screenName: isReviewPage ? 'Confirm' : 'SRP',
          ctaAction: 'CoinOn',
          screenDuration: totalScreenDuration?.toString(),
        },
        posthog
      );
    } else {
      eventsTracker(
        {
          flowName: 'Flight',
          screenName: isReviewPage ? 'Confirm' : 'SRP',
          ctaAction: 'CoinOff',
          screenDuration: totalScreenDuration?.toString(),
        },
        posthog
      );
    }
    dispatch(setIsCoinsApplied(event.target.checked));
  };
  return (
    <>
      {burnCoinsFeat && coins > 9 ? (
        <Box
          display="flex"
          alignItems="center" // This will vertically align the switch in the center
          justifyContent="space-between"
          sx={{ bgcolor: 'secondary.main', p: '10px 16px' }}
        >
          <Box display="flex" alignItems="center">
            <Typography variant="body3" sx={{ color: 'secondary.contrastText', fontWeight: 600 }}>
              {coins > 9 ? 'Show price using' : 'Earn Niyo coins'}
            </Typography>
            <OAImage
              src="3xNiyo_coin.png"
              alt="coins"
              sx={{ mx: '8px', height: '20px', width: '20px' }}
            />
            <Typography variant="body3" sx={{ color: 'secondary.contrastText', fontWeight: 600 }}>
              {coins > 9 ? `${formatToINR(coins, false)} coins` : '& save upto 75% on next booking'}
            </Typography>
          </Box>

          {coins > 9 && <OASwitch onChange={handleOnChange} checked={isCoinsApplied} />}
        </Box>
      ) : null}
    </>
  );
};
