import { FC, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { OALoadingLottie } from '../OALoadingLottie';

interface OALoadingProps {
  title?: string;
  subTitle?: string;
  loadingText?: string[];
}

const texts = [
  '🔭 Searching the skies for best deals',
  '🗺️ Your travel adventure is loading',
  '🛫 Navigating to your flight options',
  '✈️ Your flight options are on the way',
  "🎯 We're picking out the best flights",
];

export const OALoadingSearch: FC<OALoadingProps> = ({ title, subTitle, loadingText=texts }) => {
  const [index, setIndex] = useState<number>(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 3000); // Change text every 3 seconds

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Box
      sx={{
        position: 'absolute', // Make the box position absolute
        top: '50%', // Set top to 50% of the parent
        left: '50%', // Center align horizontally
        transform: 'translate(-50%, -50%)', // Adjust position to truly center
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        width: '100%',
      }}
    >
      <OALoadingLottie />
      <Typography
        variant="body3"
        sx={{ fontWeight: 400, mt: '8px', mb: '4px', color: 'text.secondary' }}
      >
        Please wait..
      </Typography>
      <Box
        sx={{
          height: 50,
          overflow: 'hidden',
          position: 'relative',
          width: '80%',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Typography
          variant="body2"
          sx={{
            animation: 'slide 3s ease-in-out infinite',
            whiteSpace: 'normal',
            fontWeight: 600,
            wordWrap: 'break-word', 
            marginLeft: 2,
            marginRight: 2,
          }}
        >
          {loadingText[index]}
        </Typography>
      </Box>
    </Box>
  );
};