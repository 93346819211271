import { Box } from '@mui/material';
import { OALoadingLottie } from './OALoadingLottie';

export const OALoadingOverlay = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        bgcolor: '#FAFAFA4D',
        zIndex: 9999,
      }}
    >
      <OALoadingLottie />
    </Box>
  );
};
