import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { size, flatten, values } from 'lodash';
import { Box, Container, Grid, Tab, Tabs, Typography } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import CloseIcon from '@mui/icons-material/Close';
import { OAButton, OAChip, OALoadingOverlay, OASwipeableDrawer } from '../../../components';
import { getFilterState, getSearchFlightState } from '../../../store/slices/searchFlightSlice';
import FilterList from './FilterList';

interface TabPanelProps {
  children: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`vertical-tabpanel-${index}`}
    aria-labelledby={`vertical-tab-${index}`}
    {...other}
  >
    {value === index && <Box sx={{ p: '16px' }}>{children}</Box>}
  </div>
);

// Custom label with a dot indicator
const TabLabel = ({ label, isSelected }: { label: string; isSelected: boolean }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
      <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
        {label}
      </Typography>
      {isSelected && <span style={{ color: '#00C6A1' }}>&bull;</span>}
    </Box>
  );
};

const FilterLabel = ({ label, total }: { label: any; total?: any }) => {
  return (
    <Box display="flex" alignItems="center" sx={{ position: 'relative' }}>
      <Typography variant="body2">{label}</Typography>
      {total && total !== 0 ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            bgcolor: 'primary.main',
            ml: '8px',
            height: '16px',
            width: '16px',
            borderRadius: '50%',
          }}
        >
          <Typography variant="body4" sx={{ color: 'primary.contrastText' }}>
            {total}
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
};

const Filter = ({
  filterData,
  filtersCount,
  countOfStops,
  countOfAirports,
  setFilterData,
  onClick,
  onClickClearFilter,
}: {
  filterData: any;
  filtersCount: any;
  countOfStops: any;
  countOfAirports: any;
  setFilterData: (values: any) => void;
  onClick: (filterData: any) => void;
  onClickClearFilter: () => void;
}) => {
  const { data } = useSelector(getSearchFlightState);
  const filterValue = useSelector(getFilterState);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [value, setValue] = useState(0);
  const [drawerKey, setDrawerKey] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!filterData) setDrawerKey((prevKey) => prevKey + 1);
  }, [filterData]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
    setValue(0);
  };

  const handleChangeFilter = (event: any, name: string) => {
    const { checked, value: targetValue } = event.target;

    setFilterData((currentFilterData: any) => {
      const updatedFilter = { ...currentFilterData };

      if (checked) {
        updatedFilter[name] = updatedFilter[name]
          ? [...updatedFilter[name], targetValue]
          : [targetValue];
      } else {
        if (updatedFilter[name]) {
          updatedFilter[name] = updatedFilter[name].filter((a: any) => a !== targetValue);
          if (updatedFilter[name].length === 0) {
            delete updatedFilter[name];
          }
        }
      }

      return updatedFilter;
    });
  };

  // Function to check if a filter is selected
  const isFilterSelected = useCallback(
    (key: string) => {
      return filterData && filterData[key] && filterData[key].length > 0;
    },
    [filterData]
  );

  const handleApplyBtn = () => {
    setIsLoading(true);
    toggleDrawer();
    onClick(filterData);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  const handleClearBtn = () => {
    setIsLoading(true);
    toggleDrawer();
    onClickClearFilter();
    setFilterData(null);
    setValue(0);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  const totalFilter = useMemo(() => size(flatten(values(filterValue))), [filterValue]);

  return (
    <>
      {isLoading && <OALoadingOverlay />}
      <OAChip
        selected={totalFilter > 0 ? true : false}
        icon={<TuneIcon fontSize="small" sx={{ color: '#5A6068 !important' }} />}
        label={<FilterLabel label="Filter" total={totalFilter} />}
        onClick={toggleDrawer}
        {...(totalFilter > 0 ? { onDelete: onClickClearFilter } : {})}
        {...(totalFilter > 0 ? { deleteIcon: <CloseIcon fontSize="small" sx={{ mr: 0 }} /> } : {})}
        disabled={data?.meta && data?.results?.onward?.length > 0 ? false : true}
      />
      <OASwipeableDrawer
        title={'Filters'}
        open={isOpen}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
        isContainer={false}
      >
        <Box
          key={drawerKey}
          sx={{
            flexGrow: 1,
            bgcolor: 'background.paper',
            display: 'flex',
            height: '100%',
            border: '1px solid #E2E2E2',
          }}
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs"
            sx={{
              borderRight: 1,
              borderColor: 'divider',
              minWidth: '100px',
              '& .MuiTabs-indicator': {
                backgroundColor: 'primary.main',
              },
            }}
          >
            {data?.meta &&
              Object.keys(data.meta).map((key, index) => {
                let tempKey = key;
                if (key === 'onwardDeparture') {
                  tempKey = 'Departure';
                } else if (key === 'returnDeparture') {
                  tempKey = 'Return';
                }
                return (
                  <Tab
                    key={key}
                    label={<TabLabel label={tempKey} isSelected={isFilterSelected(key)} />}
                    id={`vertical-tab-${index}`}
                    aria-controls={`vertical-tabpanel-${index}`}
                    sx={{
                      height: 70,
                      borderBottom: 1,
                      borderColor: 'divider',
                      bgcolor: value === index ? 'primary.light' : 'transparent',
                      px: 2,
                    }}
                  />
                );
              })}
          </Tabs>

          <Box
            sx={{
              flexGrow: 1,
              width: '100%', // Take the rest of the horizontal space
              display: 'flex',
              flexDirection: 'column',
              height: '400px',
              overflowY: 'auto',
            }}
          >
            {data?.meta &&
              Object.entries(data.meta).map(([key, filters], index) => {
                const isAirlineFilter = key === 'airlines';
                const mergedData = [
                  ...(Array.isArray(filters)
                    ? filters.filter((obj: any) => obj && obj.value !== 'I5' && obj.value !== 'IX')
                    : []),
                  ...(isAirlineFilter
                    ? [{ label: 'Air India Express', value: 'IX', merged: true }]
                    : []),
                ];
                return (
                  <TabPanel key={key} value={value} index={index}>
                    <FilterList
                      data={mergedData}
                      onChange={handleChangeFilter}
                      name={key}
                      filterData={filterData}
                      isImgShow={'airlines' === key}
                      filtersCount={filtersCount}
                      countOfStops={countOfStops}
                      countOfAirports={countOfAirports}
                    />
                  </TabPanel>
                );
              })}
          </Box>
        </Box>
        <Container sx={{ mt: '16px', mb: '24px' }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <OAButton fullWidth variant="outlined" color="secondary" onClick={handleClearBtn}>
                Clear Filter
              </OAButton>
            </Grid>
            <Grid item xs={6}>
              <OAButton onClick={handleApplyBtn} fullWidth variant="contained" color="secondary">
                Apply Filter
              </OAButton>
            </Grid>
          </Grid>
        </Container>
      </OASwipeableDrawer>
    </>
  );
};

export default Filter;
