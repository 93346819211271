import React, { useCallback, useEffect, useRef } from 'react';
import { Autocomplete, Box } from '@mui/material';
import { OATextField } from '..';
import AirportSuggestions from '../../pages/Flight/AirportSuggestions';
import { useDispatch, useSelector } from '../../store';
import {
  addFromAirportSuggestion,
  addToAirportSuggestion,
  clearAirportSuggestions,
  getAirportSearchState,
} from '../../store/slices/airportSearchSlice';

interface AutoCompleteFieldProps {
  fieldId: string;
  sx?: any;
  label: string | React.ReactNode;
  onSelect?: (value: string) => void;
  airportSuggestions?: any[];
  popularSearches?: any[];
  fetchAirportSuggestions?: (value: string) => void;
  inputValue: string;
  setInputValue: any;
  isOpen: boolean;
}

const OAAutoCompleteField: React.FC<AutoCompleteFieldProps> = ({
  fieldId,
  sx,
  label,
  onSelect,
  airportSuggestions,
  popularSearches,
  fetchAirportSuggestions,
  inputValue,
  setInputValue,
  isOpen,
}) => {
  const dispatch = useDispatch();
  let inputRef1 = useRef<HTMLInputElement | null>(null);
  const targetScroll = useRef<any>(null);
  const { fromAirportSuggestions, toAirportSuggestions } = useSelector(getAirportSearchState);
  // Helper function to get last three unique items
  const getUniqueLastThreeItems = useCallback((array: any[]) => {
    const uniqueArray = Array?.from(new Set(array?.map((item) => item?.iata)))?.map((iata) => {
      return array?.find((item) => item?.iata === iata);
    });

    return uniqueArray?.slice(Math?.max(uniqueArray?.length - 3, 0));
  }, []);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<{}>, newInputValue: string) => {
      setInputValue(newInputValue);
      if (fetchAirportSuggestions) {
        fetchAirportSuggestions(newInputValue);
        if (targetScroll?.current) {
          targetScroll.current?.scrollIntoView({
            behavior: 'smooth',
            top: 0,
          });
        }
      }
    },
    [fetchAirportSuggestions]
  );

  const handleSuggestionClick = useCallback(
    (suggestion: any) => {
      if (onSelect) {
        onSelect(suggestion);
        dispatch(clearAirportSuggestions());
        if (fieldId === 'departure') {
          dispatch(addFromAirportSuggestion(suggestion));
        } else if (fieldId === 'to') {
          dispatch(addToAirportSuggestion(suggestion));
        }
        setInputValue('');
      }
    },
    [dispatch, onSelect, fieldId]
  );

  const renderSuggestions = (suggestions: any[], keyPrefix: string) => {
    return getUniqueLastThreeItems(suggestions)?.map((suggestion, index) => (
      <Box px="16px" key={`${keyPrefix}-${index}`}>
        <AirportSuggestions
          index={index}
          suggestion={suggestion}
          handleSuggestionClick={handleSuggestionClick}
        />
      </Box>
    ));
  };

  useEffect(() => {
    if (isOpen && inputRef1?.current) {
      inputRef1.current.focus({ preventScroll: true });
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && inputRef1?.current) {
      inputRef1.current.focus({ preventScroll: true });
    }
  }, [isOpen]);

  return (
    <>
      <Box px="16px" pb="10px" mb="14px" bgcolor="white" position="sticky" top="94px">
        <Autocomplete
          freeSolo
          open={false}
          id={fieldId}
          sx={{
            ...sx,
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px',
              WebkitTouchCallout: 'none',
              WebkitUserSelect: 'none',
              MozUserSelect: 'none',
              msUserSelect: 'none',
              userSelect: 'none',
            },
            '& .MuiAutocomplete-paper': { borderRadius: '8px' },
          }}
          options={[]}
          autoHighlight
          inputValue={inputValue}
          onInputChange={handleInputChange}
          renderInput={(params) => (
            <OATextField
              {...params}
              value={inputValue}
              label={label}
              autoFocus
              inputRef={inputRef1}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'off',
              }}
            />
          )}
        />
      </Box>
      <Box sx={{ overflow: 'scroll' }}>
        <Box ref={targetScroll}></Box>
        {fieldId === 'departure' &&
          fromAirportSuggestions?.length > 0 &&
          airportSuggestions?.length === 0 && (
            <Box>
              <Box sx={{ mb: '10px', backgroundColor: '#F4F6F5', p: '10px 16px' }}>
                Continue your search
              </Box>
              {renderSuggestions(fromAirportSuggestions, 'from')}
            </Box>
          )}
        {fieldId === 'to' &&
          toAirportSuggestions?.length > 0 &&
          airportSuggestions?.length === 0 && (
            <Box>
              <Box sx={{ mb: '10px', backgroundColor: '#F4F6F5', p: '10px 16px' }}>
                Continue your search
              </Box>
              {renderSuggestions(toAirportSuggestions, 'to')}
            </Box>
          )}
        {popularSearches && popularSearches?.length > 0 && airportSuggestions?.length === 0 && (
          <Box>
            <Box sx={{ mb: '10px', backgroundColor: '#F4F6F5', p: '10px 16px' }}>
              Popular Searches
            </Box>
            {popularSearches
              ?.filter((suggestion) => suggestion?.airport)
              ?.map((suggestion, index) => (
                <Box px="16px" key={`popular-${index}`}>
                  <AirportSuggestions
                    index={index}
                    suggestion={suggestion.airport}
                    handleSuggestionClick={handleSuggestionClick}
                  />
                </Box>
              ))}
          </Box>
        )}
        {airportSuggestions && airportSuggestions?.length > 0 && (
          <Box>
            <Box sx={{ mb: '10px', backgroundColor: '#F4F6F5', p: '10px 16px' }}>Results</Box>
            <Box sx={{ px: '16px' }}>
              {airportSuggestions?.map((suggestion, index) => (
                <AirportSuggestions
                  index={index}
                  suggestion={suggestion}
                  handleSuggestionClick={handleSuggestionClick}
                />
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default OAAutoCompleteField;
