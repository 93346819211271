import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { OAButton } from './basic/OAButton';
import { OAImage } from './OAImage';

interface OAEmptyProps {
  onClickBtn?: () => void;
  bodytext?: string;
  bodySubtext?: string;
  buttonText?: string;
  error?: any;
  illustration?: any;
  buttonWidth?: string;
  width?: number;
  height?: number;
  customButtonHeight?: boolean;
}
export const OAEmpty: FC<OAEmptyProps> = ({
  onClickBtn,
  bodytext = 'Uh-oh..something went wrong',
  bodySubtext,
  buttonText = 'Try Again',
  error,
  illustration = <OAImage src="person-without-phone.svg" alt="edit" />,
  buttonWidth = '160px',
  width = 140,
  height = 140,
  customButtonHeight = false,
}) => {
  let errorMessage = null;
  if (error?.data?.errors?.length > 0) {
    errorMessage = error?.data?.errors?.map((obj: any) => obj.message).join(', ');
  } else if (error?.data?.errors?.length === 0 && error?.data?.message) {
    errorMessage = error?.data?.message;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%', // Take full height of the container
        textAlign: 'center', // Center the text
      }}
    >
      {illustration}
      <Typography variant="body2" sx={{ color: 'text.secondary', mt: '10px' }}>
        {bodytext}
      </Typography>
      {(errorMessage || bodySubtext) && (
        <Typography variant="body3" sx={{ color: 'grey.800', mt: '10px', fontSize: '14px' }}>
          {errorMessage ?? bodySubtext}
        </Typography>
      )}
      {onClickBtn && (
        <OAButton
          variant="contained"
          color="secondary"
          sx={{
            minWidth: buttonWidth,
            mt: '16px',
            height: customButtonHeight ? '38px' : 0,
            minHeight: customButtonHeight ? 0 : '50px',
          }}
          onClick={onClickBtn}
        >
          {buttonText}
        </OAButton>
      )}
    </Box>
  );
};
