import { IFlight } from '../type';

export const sortFlights = (flights: IFlight[], sortValue: string) => {
  let sortedFlights = [...flights]; // Create a copy of the flights array
  switch (sortValue) {
    case 'plh': // Price - low to high
      sortedFlights.sort((a, b) => a.price?.value - b.price?.value);
      break;

    case 'phl': // Price - high to low
      sortedFlights.sort((a, b) => b.price?.value - a?.price.value);
      break;

    case 'ed': // Early departure
      sortedFlights.sort((a, b) => {
        let departureA: any = new Date(
          a.segments[0].departure.date + ' ' + a.segments[0].departure.time
        );
        let departureB: any = new Date(
          b.segments[0].departure.date + ' ' + b.segments[0].departure.time
        );
        return departureA - departureB;
      });
      break;

    case 'ld': // Late departure
      sortedFlights.sort((a, b) => {
        let departureA: any = new Date(
          a.segments[0].departure.date + ' ' + a.segments[0].departure.time
        );
        let departureB: any = new Date(
          b.segments[0].departure.date + ' ' + b.segments[0].departure.time
        );
        return departureB - departureA;
      });
      break;

    case 'dsf':
      sortedFlights?.sort((a, b) => {
        const durationInfoA = a?.durationInfo?.seconds ?? a?.returnDurationInfo?.seconds;
        const durationInfoB = b?.durationInfo?.seconds ?? b?.returnDurationInfo?.seconds;
        return durationInfoA - durationInfoB;
      });
      break;

    default:
      // No sorting or unknown sort value
      break;
  }

  return sortedFlights;
};
