import React, { useState, useEffect, useRef } from 'react';
import { Box, Tooltip } from '@mui/material';
import SvgIcon from './SeatSvg';
import { addSeatSelection, getSmbData } from '../../../../store/slices/flightOrderSlice';
import { useDispatch, useSelector } from '../../../../store';
import { eventsTracker } from '../../../../utils/ctEventsTracking';
import { usePostHog } from 'posthog-js/react';
import { disableSeat } from '../../helpers/disableSeats';

interface SeatProps {
  seat: any;
  totalTravellers: number;
  updateSmbAction: any;
  selectedSegment: { from: string; to: string };
  apiResponse: any;
  priceColorArray: any;
}

const Seat: React.FC<SeatProps> = ({
  seat,
  totalTravellers,
  updateSmbAction,
  selectedSegment,
  apiResponse,
  priceColorArray,
}) => {
  const posthog = usePostHog();
  const dispatch = useDispatch();
  const smbData = useSelector(getSmbData);
  const seatRef = useRef<HTMLDivElement>(null);
  const { seatNumber, isAvailable, price, seatCharacteristics } = seat;

  const isDisabled = apiResponse?.some((response: any) =>
    response.segmentAncillaries?.some((segment: any) => 
      disableSeat(seatNumber, segment.flight?.airlineCode)
    )
  );
  
  const isSelected = smbData?.userSelectedJourneyAncillaries?.some((journey: any) =>
    journey.userSelectedSegmentAncillaries.some(
      (segment: any) =>
        segment.segmentOriginDestinationInfo.from === selectedSegment.from &&
        segment.segmentOriginDestinationInfo.to === selectedSegment.to &&
        segment.seatDetails.some(
          (s: any) => s.columnId === seat.columnId && s.rowId === seat.seatCoordinates.xcoordinate
        )
    )
  );

  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const [startTime, setStartTime] = useState(Date.now());

  useEffect(() => {
    setStartTime(Date.now());
  }, []);

  const handleSelectSeat = (seat: any) => {
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);
    const { columnId, seatCoordinates } = seat;
    const seatDetails = { columnId, rowId: seatCoordinates.xcoordinate, price };
    if (isSelected) {
      eventsTracker(
        {
          flowName: 'Flight',
          screenName: 'SMB',
          ctaAction: 'SeatRemove',
          screenDuration: totalScreenDuration?.toString(),
          otherData: {
            ...seatDetails,
          },
        },
        posthog
      );
    } else {
      eventsTracker(
        {
          flowName: 'Flight',
          screenName: 'SMB',
          ctaAction: 'SeatAdd',
          screenDuration: totalScreenDuration?.toString(),
          otherData: {
            ...seatDetails,
          },
        },
        posthog
      );
    }

    dispatch(
      addSeatSelection({
        from: selectedSegment.from,
        to: selectedSegment.to,
        seatDetails,
        totalTravellers,
        apiResponse,
      })
    );

    // closing all other tooltips and open this one
    window.dispatchEvent(new CustomEvent('closeSeatTooltips'));
    setTooltipOpen(true);
  };

  useEffect(() => {
    const handleCloseTooltips = () => {
      setTooltipOpen(false);
    };

    const handleScroll = () => {
      if (tooltipOpen) {
        setTooltipOpen(false);
      }
    };

    window.addEventListener('closeSeatTooltips', handleCloseTooltips);
    window.addEventListener('scroll', handleScroll, true);

    return () => {
      window.removeEventListener('closeSeatTooltips', handleCloseTooltips);
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [tooltipOpen]);

  useEffect(() => {
    if (tooltipOpen) {
      const timer = setTimeout(() => {
        setTooltipOpen(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [tooltipOpen]);

  const getColorByPrice = (price: number): { primaryColor: string; secondaryColor: string } => {
    if (!priceColorArray || priceColorArray?.length === 0)
      return { primaryColor: '#E6EAE9', secondaryColor: '#8C9097' };

    const maxPriceCategory = priceColorArray[priceColorArray.length - 1];
    if (price >= maxPriceCategory.price) {
      return {
        primaryColor: maxPriceCategory?.primaryColor,
        secondaryColor: maxPriceCategory?.secondaryColor,
      };
    }

    const colorMapping = priceColorArray.find((item: any) => item.price === price);
    return colorMapping
      ? { primaryColor: colorMapping?.primaryColor, secondaryColor: colorMapping?.secondaryColor }
      : { primaryColor: '#E6EAE9', secondaryColor: '#8C9097' };
  };

  const { primaryColor, secondaryColor } = getColorByPrice(price);

  useEffect(() => {
    if (isSelected && seatRef.current) {
      seatRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, []);

  return (
    <Tooltip
      title={
        <span style={{ color: 'white' }}>
          {`${seatNumber}`}
          <span style={{ margin: '0 8px' }} />₹{price.toLocaleString('en-IN')}
        </span>
      }
      arrow
      placement="top"
      open={tooltipOpen && isSelected}
      PopperProps={{
        modifiers: [
          {
            name: 'arrow',
            options: {
              padding: 8,
            },
          },
        ],
      }}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: 'black',
            borderRadius: '4px',
            fontSize: '14px',
            padding: '8px',
            left: '14px',
            marginBottom: '2px !important',
            transformOrigin: 'left center',
          },
        },
        arrow: {
          sx: {
            color: 'black',
            borderRadius: '2px',
            left: '-14px !important',
          },
        },
      }}
    >
      <Box
        ref={seatRef}
        width="32px"
        height="34px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        m="2px"
        borderRadius="10px"
        onClick={() => {
          if (isAvailable && !isDisabled) handleSelectSeat(seat);
        }}
        sx={{
          cursor: isAvailable ? 'pointer' : 'default',
        }}
      >
        <SvgIcon
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
          isAvailable={isAvailable && !isDisabled}
          isSelected={isSelected}
        />
      </Box>
    </Tooltip>
  );
};

export default Seat;
