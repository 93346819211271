import { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Box, Divider, Typography } from '@mui/material';
import {
  OAButton,
  OAHeader,
  OAHeaderSubTitle,
  OAHeaderTitle,
  OAImage,
  OAScrollToTop,
  OASwipeableDrawer,
} from '../../../components';
import ContactSupport from './ContactSupport';
import { useGetBookingsListQuery, useGetOrderByIdQuery } from '../../../services/bookingsApi';
import StatusMsgAndBtn from './StatusMsgAndBtn';
import TripDetailsSegments from './TripDetailsSegments';
import TravellerDetails from './TravellerDetails';
import PaymentAndInvoice from './PaymentAndInvoice';
import CancelBooking from './CancelBooking';
import ImportantInfo from './ImportantInfo';
import PrimaryContactDetails from './PrimaryContactDetails';
import RefundDetails from './RefundDetails';
import { bookingStatusLabels } from '../../../constants';
import SomethingWentWrong from '../../Review/SomethingWentWrong';
import AddOnsSummary from '../../../components/OASMB/components/AddOnsSummary';
import { getFeatureFlag } from '../../../store/slices/featuresSlice';
import { useSelector } from '../../../store';
import useIsVisible from '../../../hooks/useIsVisible';
import { formatToINR } from '../../../utils';
import OADialog from '../../../components/OADialog';
import PayAnimation from '../../../components/OADialog/PayAnimation.json';
import lottie from 'lottie-web-light';

const BookingStatus = () => {
  const { orderId } = useParams();
  const lottieContainer = useRef<any>(null);

  const {
    data,
    isLoading,
    isSuccess: isOrderSuccess,
    isError: isErrorOrderById,
    refetch: refetchOrderById,
  } = useGetOrderByIdQuery(orderId);
  const { smbFeat } = useSelector(getFeatureFlag);

  const { refetch: refetchBookingsList } = useGetBookingsListQuery({});
  const [isUnsuccessfulBooking, setIsUnsuccessfulBooking] = useState<boolean>(false);
  const [unsuccessfulBookingText, setUnsuccessfulBookingText] = useState<any>('');

  const pageRef = useRef<HTMLDivElement>(null);
  const isVisible = useIsVisible(pageRef);
  const [isApiError, setIsApiError] = useState<boolean>(false);
  const flight = data?.data?.flights?.[0];
  const segments = flight?.segments ?? [];
  const location = useLocation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const importantInfo = segments?.[0]?.baggageInfo?.baggages?.[0]?.info;

  const headerInfo = {
    from: { city: data?.data?.flightSearchInfo?.from?.city },
    to: { city: data?.data?.flightSearchInfo?.to?.city },
    tripType: data?.data?.tripType,
  };

  const headerSecondaryInfo = {
    travellerCount: data?.data?.travellerCount,
    tripStart: data?.data?.tripStart,
    tripEnd: data?.data?.tripEnd,
    cabinType:
      data?.data?.flights?.[0]?.ticketNumberInfo?.[0]?.cabinType?.toLowerCase() ??
      data?.data?.flightSearchInfo?.cabinType,
    tripType: data?.data?.tripType,
  };

  useEffect(() => {
    if (isErrorOrderById) setIsApiError(true);
  }, [isErrorOrderById]);

  useEffect(() => {
    if (isOrderSuccess) {
      refetchBookingsList();
    }
  }, [isOrderSuccess, refetchBookingsList]);

  const toggleApiError = () => setIsApiError(true);

  const eventFreshChat = () => {
    if (
      window.flutter_inappwebview &&
      window.niyo_fresh_chat &&
      typeof window.niyo_fresh_chat === 'function'
    ) {
      let appSubStage = `${data?.data?.bookingStatus?.toLowerCase()}`;
      const status = bookingStatusLabels?.find(
        (obj: any) => data?.data?.bookingStatus === obj?.value
      )?.label;
      if (status) {
        appSubStage = status?.toLowerCase();
      }
      const freshChatData = {
        appTag: 'oa_onarrival_mytrips',
        appSpoke: 'onarrival',
        appStage: 'onarrival_mytrips',
        appSubStage: `onarrival_mytrips_${appSubStage}`,
        faqTag: ['oa_onarrival_mytrips'],
      };
      const freshChatDataStringify = JSON.stringify(freshChatData);
      window.niyo_fresh_chat(freshChatDataStringify);
    } else {
      console.log('only allowed in mobile');
    }
  };

  const onwardFlights = data?.data?.flights?.find(
    (flight: any) => flight?.departureType === 'ONWARD'
  );
  const returnFlights = data?.data?.flights?.find(
    (flight: any) => flight?.departureType === 'RETURN'
  );

  const segmentBasedAddOns = () => {};

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    if (isVisible && data?.data?.bookingStatus === 'BOOKING_IN_PROGRESS') {
      intervalId = setInterval(() => {
        refetchOrderById();
        refetchBookingsList();
      }, 5000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isVisible, data?.data?.bookingStatus]);

  const bookingFailedText = () => (
    <Box>
      <Typography variant="h6" lineHeight="25px" mb="10px" fontWeight="600">
        Your booking has failed
      </Typography>
      <Typography>Don't worry — any deducted amount will be refunded within 7 days.</Typography>
      <Typography mt="16px">
        Please don't hesitate to reach out to our customer support team for any queries.
      </Typography>
    </Box>
  );

  const paymentFailedText = () => (
    <Box>
      <Typography variant="h6" lineHeight="25px" mb="10px" fontWeight="600">
        Your payment has failed
      </Typography>
      <Typography>
        Your payment for the{' '}
        {data?.data?.flightSearchInfo?.tripType === 'ONWARD' ? 'oneway' : 'return'} flight booking
        from {data?.data?.flightSearchInfo?.from?.city} to {data?.data?.flightSearchInfo?.to?.city}{' '}
        has failed
      </Typography>
    </Box>
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(location?.search);
    const review = searchParams.get('review');
    if (review === '' && data?.data?.bookingStatus === 'BOOKING_IN_PROGRESS') {
      setIsDialogOpen(true);
    } else if (review === '' && data?.data?.bookingStatus === 'PAYMENT_FAILED') {
      setIsUnsuccessfulBooking(true);
      setUnsuccessfulBookingText(paymentFailedText);
    } else if (review === '' && data?.data?.bookingStatus === 'BOOKING_FAILED') {
      setIsUnsuccessfulBooking(true);
      setUnsuccessfulBookingText('Your booking has failed');
      setUnsuccessfulBookingText(bookingFailedText);
    }
  }, [location, data?.data]);

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const dialogContent = (
    <Box sx={{ p: 2, textAlign: 'center', py: '30px', px: '25px' }} bgcolor="#F1E3FD" borderRadius='20px'>
      <Typography variant="body2" my="2px" fontWeight="500">
        Payment completed!
      </Typography>
      <Typography variant="h6" lineHeight="25px" paragraph mt="20px" color="#5F1D8B">
        {`You paid ${formatToINR(data?.data?.orderAmount?.totalAmount)} on this flight booking`}
      </Typography>
      <Typography mt="15px" variant="body2" fontWeight="500">
        Cheers!
      </Typography>
    </Box>
  );

  const toggleUnsuccessfulBooking = () => {
    setIsUnsuccessfulBooking(!isUnsuccessfulBooking);
  };

  useEffect(() => {
    if (lottieContainer.current) {
      const animation = lottie?.loadAnimation({
        container: lottieContainer.current,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: PayAnimation,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      });
      return () => {
        if (animation) {
          animation.destroy(); // Cleanup animation on component unmount
        }
      };
    }
  }, [isDialogOpen]);
  
  return (
    <Box ref={pageRef}>
      <OAScrollToTop />
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 100,
          bgcolor: 'background.paper',
        }}
      >
        <OAHeader
          title={<OAHeaderTitle travelDetail={headerInfo} />}
          subTitle={<OAHeaderSubTitle travelDetail={headerSecondaryInfo} />}
          loading={isLoading}
          action={
            <Box onClick={eventFreshChat}>
              <OAImage src="chatbot.svg" folder="icons" alt="chat" />
            </Box>
          }
        />
      </Box>
      <StatusMsgAndBtn order={data?.data} isLoading={isLoading} />
      {(data?.data?.bookingStatus === 'CANCELLED' ||
        data?.data?.bookingStatus === 'BOOKING_FAILED') && <RefundDetails data={data} />}
      <TripDetailsSegments order={data?.data} isLoading={isLoading} />
      <TravellerDetails traveller={data?.data?.travellers} isLoading={isLoading} />
      {data?.data?.selectedJourneyAncillaries?.length > 0 && smbFeat && (
        <>
          {' '}
          <AddOnsSummary order={data?.data} isBookingStatusPage />
          <Divider sx={{ borderBottomWidth: '4px', borderColor: '#F4F6F5', my: '20px' }} />
        </>
      )}
      <PaymentAndInvoice order={data?.data} isLoading={isLoading} />
      {![
        'CANCELLED',
        'BOOKING_FAILED',
        'BOOKING_IN_PROGRESS',
        'PROCESSING_OFFLINE',
        'PAYMENT_FAILED',
        'CANCELLATION_REQUESTED',
      ]?.includes(data?.data?.bookingStatus) &&
        data?.data?.tripEndDate &&
        new Date(data?.data?.tripEndDate) >= new Date() && <CancelBooking data={data?.data} />}
      <ImportantInfo info={importantInfo} />
      <PrimaryContactDetails contactDetails={data?.data?.contactDetails} />
      <ContactSupport onClick={eventFreshChat} bookingId={data?.data?.bookingId} />
      <OADialog open={isDialogOpen} content={dialogContent} handleClose={handleCloseDialog} />
      {isDialogOpen && (
        <Box position="sticky" bottom="0" sx={{ zIndex: 13000 }}>
          <div ref={lottieContainer} style={{ width: '80%' }} />
        </Box>
      )}
      <OASwipeableDrawer
        title={<OAImage src="error-icon.svg" folder="icons" alt="Erro" />}
        open={isApiError}
        onOpen={toggleApiError}
        onClose={toggleApiError}
        isContainer={false}
        isError={true}
      >
        <SomethingWentWrong />
      </OASwipeableDrawer>
      <OASwipeableDrawer
        title={<OAImage src="error-icon.svg" folder="icons" alt="error" />}
        open={isUnsuccessfulBooking}
        onOpen={toggleUnsuccessfulBooking}
        onClose={toggleUnsuccessfulBooking}
        isError={true}
      >
        <Typography>{unsuccessfulBookingText}</Typography>
        <OAButton
          variant="contained"
          color="secondary"
          fullWidth
          sx={{ my: '30px' }}
          onClick={toggleUnsuccessfulBooking}
        >
          Okay
        </OAButton>
      </OASwipeableDrawer>
    </Box>
  );
};

export default BookingStatus;
