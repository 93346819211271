import React, { useEffect, useRef } from 'react';
import { Grid, Box } from '@mui/material';
import FlightCard from './FlightCard';
import { IFlight } from '../../type';
import { VariableSizeList } from 'react-window';
import { OAImage } from '../../components';
import { useSelector } from '../../store';
import { getFeatureFlag } from '../../store/slices/featuresSlice';
import { getUserInfo } from '../../store/slices/userInfoSlice';
import { getAuthInfo } from '../../store/slices/authSlice';
import { getUserInputState } from '../../store/slices/userInputSlice';

interface FlightsListProps {
  flights: IFlight[];
  onFlightSelect: (flight: IFlight) => void;
  tripType: 'R' | 'O';
  isInternational: boolean;
  selectedFlight: IFlight;
  fastestFlight: IFlight;
  lowestPriceFlight: IFlight;
  isFastestAndLowestSame: boolean;
}

const FlightsList: React.FC<FlightsListProps> = ({
  flights,
  onFlightSelect,
  tripType,
  isInternational,
  fastestFlight,
  lowestPriceFlight,
  isFastestAndLowestSame,
}) => {
  const listRef = useRef<VariableSizeList>(null);
  const { isCoinsApplied } = useSelector(getUserInputState);
  const { coins } = useSelector(getUserInfo);
  const { burnCoinsFeat, noConvenienceFeeBanner, esimOffer } = useSelector(getFeatureFlag);
  const { campaigns } = useSelector(getAuthInfo);
  
  useEffect(() => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(0);
    }
  }, [isCoinsApplied, campaigns, coins, burnCoinsFeat]);

  const getItemSize = (index: number) => {
    if (index === 2 && noConvenienceFeeBanner) {
      return (isInternational && tripType === 'R') ? 124 : 104; // Height for the banner
    }

    if (index === 6 && isInternational && esimOffer) { // for the eSIM banner
      return (isInternational && tripType === 'R') ? 124 : 104; // height as needed for the eSIM banner
    }
    
    const baseHeight = 175;
    const campaignHeight = campaigns?.eligibleCampaigns?.length > 0 ? 30 : 0;
    const coinHeight = (isCoinsApplied && coins > 0 && burnCoinsFeat) ? 30 : 0;
    
    return baseHeight + campaignHeight + coinHeight;
  };

  const Row = ({ index, style }: any) => {
    if (index === 2 && noConvenienceFeeBanner) {
      return (
        <div style={style}>
          <Box sx={{ marginBottom: '15px' }} py='10px'>
            <OAImage src='no-convenience-fee.svg' folder='icons' alt='no-convenience-fee' width='100%' />
          </Box>
        </div>
      );
    }

    if (index === 6 && esimOffer) { // condition for the eSIM banner
      return (
        <div style={style}>
          <Box sx={{ marginBottom: '15px' }} py='10px'>
            <OAImage src='esim-srp.svg' folder='offer' alt='esim-banner' width='100%' />
          </Box>
        </div>
      );
    }

    const flightIndex = noConvenienceFeeBanner && index > 2 ? index - 1 : index;
    const flight = flights[flightIndex];
  
    // added this check to prevent rendering undefined flight cards
    if (!flight) {
      return null;
    }

    return (
      <div style={style}>
        <Box key={flight?.id} sx={{ marginBottom: '15px' }} pt="10px" px='16px'>
          <FlightCard
            data={flight}
            onClick={() => onFlightSelect(flight)}
            tripType={tripType}
            isInternational={isInternational}
            isLowestPrice={lowestPriceFlight && flight?.id === lowestPriceFlight?.id}
            isFastestFlight={fastestFlight && flight?.id === fastestFlight?.id}
            isFastestAndLowest={(flight?.id === fastestFlight?.id) && (flight?.id === lowestPriceFlight?.id)}
          />
        </Box>
      </div>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12} sx={{ height: '100%', overflowY: 'auto' }} mb="35px" pt="10px">
        {isInternational && tripType === 'R' ? (
          <VariableSizeList
            ref={listRef}
            height={1500}
            width="100%"
            itemSize={getItemSize}
            itemCount={flights?.length + (noConvenienceFeeBanner ? 1 : 0)} // Add 1 for the banner if applicable
          >
            {Row}
          </VariableSizeList>
        ) : (
          <>
            {flights?.slice(0, 2).map((flight) => (
              <Box key={flight?.id} sx={{ marginBottom: '15px' }} px='16px'>
                <FlightCard
                  data={flight}
                  onClick={() => onFlightSelect(flight)}
                  tripType={tripType}
                  isInternational={isInternational}
                  isLowestPrice={lowestPriceFlight && flight?.id === lowestPriceFlight?.id}
                  isFastestFlight={fastestFlight && flight?.id === fastestFlight?.id}
                  isFastestAndLowest={(flight?.id === fastestFlight?.id) && (flight?.id === lowestPriceFlight?.id)}
                />
              </Box>
            ))}
            {noConvenienceFeeBanner && (
              <Box sx={{ marginBottom: '15px' }}>
                <OAImage src='no-convenience-fee.svg' folder='icons' alt='no-convenience-fee' width='100%' />
              </Box>
            )}
             {flights?.slice(noConvenienceFeeBanner ? 2 : 0, esimOffer ? 5 : flights?.length)?.map((flight) => (
              <Box key={flight?.id} sx={{ marginBottom: '15px' }} px='16px'>
                <FlightCard
                  data={flight}
                  onClick={() => onFlightSelect(flight)}
                  tripType={tripType}
                  isInternational={isInternational}
                  isLowestPrice={lowestPriceFlight && flight?.id === lowestPriceFlight?.id}
                  isFastestFlight={fastestFlight && flight?.id === fastestFlight?.id}
                  isFastestAndLowest={isFastestAndLowestSame && flight.id === fastestFlight?.id}
                />
              </Box>
            ))}
            {esimOffer && isInternational &&
              <Box mb='15px'>
                <OAImage src='esim-srp.svg' folder='offer' alt='esim-banner' width='100%' />
              </Box>
            }
            {esimOffer && flights?.slice(5)?.map((flight) => (
              <Box key={flight?.id} sx={{ marginBottom: '15px' }} px='16px'>
              <FlightCard
                data={flight}
                onClick={() => onFlightSelect(flight)}
                tripType={tripType}
                isInternational={isInternational}
                isLowestPrice={lowestPriceFlight && flight?.id === lowestPriceFlight?.id}
                isFastestFlight={fastestFlight && flight?.id === fastestFlight?.id}
                isFastestAndLowest={isFastestAndLowestSame && flight.id === fastestFlight?.id}
              />
            </Box>
            ))}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default FlightsList;