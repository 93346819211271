export const addAirlineLogo = (data: any) => {
  try {
    const newData = {
      ...data,
      flights: data.flights.map((flight: any) => {
        const newSegments = flight.segments.map((segment: any) => {
          if (segment.airline) {
            // Clone the airline object with the logo and recreate the segment object
            const newAirline = {
              ...segment.airline,
              logo: `https://oa-flight-assets.s3.ap-south-1.amazonaws.com/airline-logos/${segment.airline?.code}.svg`,
            };
            return { ...segment, airline: newAirline };
          }
          return segment;
        });
        // Recreate the flight object with new segments
        return { ...flight, segments: newSegments };
      }),
    };
    return newData;
  } catch (error) {
    console.log(error);
  }
  return data;
};
