import { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Box, IconButton, Stack, SxProps, Toolbar, Typography } from '@mui/material';
import { BookingStatusSkeleton } from '../pages/MyBooking/BookingStatus/BookingStatusSkeleton';
import { OAImage } from '.';
import { eventsTracker } from '../utils/ctEventsTracking';
import { usePostHog } from 'posthog-js/react';
import { useSelector } from '../store';
import { getAuthInfo } from '../store/slices/authSlice';

export const HANDLER_NAMES = {
  GET_NIYO_COINS: 'eventCoins',
  // Add other handler names as needed
};

interface OAHeaderProps {
  title: string | ReactNode;
  subTitle?: string | ReactNode;
  showBackButton?: boolean;
  appBarSx?: SxProps;
  action?: ReactNode;
  loading?: boolean;
  backgroundImageText?: ReactNode;
}

export const OAHeader = ({
  title,
  subTitle,
  showBackButton = true,
  appBarSx,
  action,
  loading = false,
  backgroundImageText,
}: OAHeaderProps) => {
  const navigate = useNavigate();
  const posthog = usePostHog();
  const { deviceInfo } = useSelector(getAuthInfo);
  const isHomePage = window.location.pathname === '/' || window.location.pathname === '/home'; // Adjust '/home' as needed
  const handleBackBtnClick = () => {
    // Assuming window.flutter_inappwebview is correctly set up and available

    // Check if the current URL is the home page or contains a hash
    const isHomePage = window.location.pathname === '/' || window.location.pathname === '/home'; // Adjust '/home' as needed
    const hasHash = window.location.hash !== '';
    const isRedirectToApp = window?.location?.href?.includes('?redirectToApp');
    const isResultsPage = window.location.pathname === '/results';
    const isBookingPage = window?.location?.href?.includes('?review');
    const isBookingStatusPage = window?.location?.href?.includes('?overview');

    if (isHomePage || hasHash || isRedirectToApp) {
      console.log('Go back to app');
      if (window.flutter_inappwebview) {
        eventsTracker(
          {
            flowName: 'Flight',
            screenName: 'Home',
            ctaAction: 'ExitFlight',
            screenDuration: '1',
            UserAction: {
              userAction: 'user has exited flight',
            },
          },
          posthog
        );
        window.back();
      }
    } else if (isBookingStatusPage) {
      navigate(`/my-bookings?review`);
    } else if (isBookingPage) {
      navigate('/');
    } else {
      console.log('Go back in history');
      if (isResultsPage) navigate('/');
      else if (isHomePage) {
        if (window.flutter_inappwebview) {
          window.back();
        }
      } else {
        navigate(-1);
      }
    }
  };

  // Hardware back
  useEffect(() => {
    window.oaBack = () => {
      handleBackBtnClick();
    };
  }, []);

  const ratio = window.devicePixelRatio;
  const statusBarHeight =
    deviceInfo && deviceInfo?.statusBarHeight ? parseInt(deviceInfo?.statusBarHeight, 10) : 0;
  const scale = statusBarHeight > 0 ? Math.round(statusBarHeight / ratio) : 0;

  return (
    <AppBar
      position="static"
      sx={{
        ...appBarSx,
        ...{
          pt: scale > 0 ? scale + 'px' : '0',
        },
      }}
    >
      <Toolbar>
        {showBackButton && (
          <IconButton
            edge={false}
            aria-label="back"
            onClick={handleBackBtnClick}
            sx={{
              padding: '16px 12px 16px 20px',
              ml: '-20px',
              color: 'white',
            }}
          >
            {isHomePage ? (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ height: '40px', width: '40px', borderRadius: '50%', bgcolor: '#FFFFFF' }}
              >
                <OAImage src="arrow-back.svg" folder="icons" alt="Go back" />
              </Box>
            ) : (
              <OAImage src="arrow-back.svg" folder="icons" alt="Go back" />
            )}
          </IconButton>
        )}

        {!loading ? (
          <Stack direction="column" justifyContent="flex-end" alignItems="flex-start">
            {typeof title === 'string' ? (
              <Typography variant="body1" sx={{ fontWeight: 400 }}>
                {title}
              </Typography>
            ) : (
              title
            )}

            {typeof subTitle === 'string' ? (
              <Typography variant="body3" sx={{ color: 'grey.800', fontWeight: 400 }}>
                {subTitle}
              </Typography>
            ) : (
              subTitle
            )}
          </Stack>
        ) : (
          <BookingStatusSkeleton />
        )}

        <Box sx={{ flexGrow: 1 }} />
        {action && action}
      </Toolbar>
      {backgroundImageText && backgroundImageText}
    </AppBar>
  );
};
