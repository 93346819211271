import { Box, Chip, Typography } from '@mui/material';
import { IFlight } from '../../../type';
import { getAirlineName, getDiscountPrice, getUniqueAirlines } from '../../../utils';
import OAFlightAvatar from '../../../components/OAFlightAvatar';
import { useSelector } from '../../../store';
import { getUserInputState } from '../../../store/slices/userInputSlice';
import { getUserInfo } from '../../../store/slices/userInfoSlice';

const AirlineDetail = ({
  data,
  tripType,
  isInternational,
}: {
  data: IFlight;
  tripType: 'R' | 'O';
  isInternational?: boolean;
}) => {
  const { isCoinsApplied } = useSelector(getUserInputState);
  const { coins } = useSelector(getUserInfo);
  // filtering out segments to only include unique airlines
  const uniqueAirlines = getUniqueAirlines(data?.segments);
  const airline = data?.segments?.[0]?.airline;
  const discountPrice = getDiscountPrice(data?.price?.value, coins);
  const isDiscountedPrice = discountPrice < data?.price?.value;
  const isDomesticReturn = tripType === 'R' && !isInternational;

  return (
    <Box
      display="flex"
      alignItems={tripType === 'O' ? 'center' : 'start'}
      justifyContent="space-between"
      sx={{ mb: '15px' }}
      px={isDomesticReturn ? '10px' : '15px'}
      pt={isDomesticReturn ? '10px' : '15px'}
    >
      <Box mr="10px" display="flex" alignItems="center" overflow="hidden">
        <OAFlightAvatar
          data={uniqueAirlines}
          sx={{ marginRight: '-12px', position: 'revert', width: '20px', height: '20px' }}
        />
        <Typography
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
          variant={isDomesticReturn ? 'body4' : 'body3'}
          sx={{ ml: '16px', color: 'grey.800' }}
        >
          {data?.stops?.value > 0 && uniqueAirlines?.length > 1
            ? 'Multiple Airlines'
            : tripType !== 'O'
            ? `${airline?.code}-${airline?.flightNumber}`
            : `${getAirlineName(airline)} • ${airline?.code}-${airline?.flightNumber}`}
        </Typography>
      </Box>
      {isDomesticReturn && (
        <Box display="flex" flexDirection={'column'} alignItems="end">
          <Typography
            variant="body1"
            sx={{
              color: isCoinsApplied
                ? discountPrice > 0
                  ? 'secondary.main'
                  : 'success.main'
                : 'secondary.main',
              fontWeight: 600,
            }}
          >
            {data?.perAdultPricing?.display ?? data?.price?.display}
          </Typography>
          {data?.perAdultPricing?.display && <Typography variant='body4'>per adult</Typography>}
        </Box>
      )}
    </Box>
  );
};

export default AirlineDetail;
