import { DateField, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';

interface OADatePopupProps {
  value: any;
  onChange: any;
  endDate?: any;
  startDate?: any;
  dateFormat: string;
  label: string;
  sx?: any;
  error?: boolean;
  helperText?: string;
  onBlur?: any;
  onKeyDown?: any;
}

const OADatePopup = ({
  value,
  onChange,
  endDate,
  startDate,
  label,
  dateFormat = 'dd/MM/yyyy',
  sx,
  error = false,
  helperText = '',
  onBlur,
  onKeyDown,
}: OADatePopupProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateField
        label={label}
        value={value}
        onChange={onChange}
        sx={sx}
        format={dateFormat}
        maxDate={endDate}
        minDate={startDate}
        slotProps={{ textField: { variant: 'filled', error, helperText, onBlur, onKeyDown, } }}
      />
    </LocalizationProvider>
  );
};

export default OADatePopup;
