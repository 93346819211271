import CancellationChargesInfo from "./CancellationChargesInfo";

const CancellationPolicy = ({ data }: any) => {
  const groupedByTravellerType = data?.cancellationFareRule && data?.cancellationFareRule?.reduce((acc: Record<string, any[]>, item: any) => {
    const type = item?.travellerType;
    if (!acc[type]) {
      acc[type] = [];
    }
    acc[type]?.push(item);
    return acc;
  }, {});

  return (
     <CancellationChargesInfo data={groupedByTravellerType} />
  );
};

export default CancellationPolicy;
