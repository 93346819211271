import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Box, ThemeProvider } from '@mui/material';
import { Provider } from 'react-redux';
import { store, persistor, useDispatch } from './store';
import AppRoutes from './routes';
import { theme } from './config';
import './assets/scss/style.scss';
import { PersistGate } from 'redux-persist/integration/react';
import { OASnackbar } from './components/basic/OASnackbar';
import { cleanupWindowFunctions, initializeWindowFunctions } from './windowFunctions';

import { OALoading } from './components';
import { userLogin } from './utils/userLogin';
import { isTestEmailForProd, parseObject } from './utils';
import NetworkDetector from './NetworkDetector';
import { useLazyGetCampaignsQuery } from './services/authApi';
import VisibilityObserver from './VisibilityObserver';
import { setCampaigns, setDeviceInfo } from './store/slices/authSlice';
import { setSmbFeat } from './store/slices/featuresSlice';
import { setStudentFareFeat } from './store/slices/featuresSlice';
import VConsole from 'vconsole';
import ErrorBoundary from './components/ErrorBoundary';

interface InitializationWrapperProps {
  children: React.ReactNode; // Explicitly type the children prop
}

export const useInitialization = () => {
  const params = new URLSearchParams(window.location.search);
  const [trigger] = useLazyGetCampaignsQuery({});
  const dispatch = useDispatch();
  const token = params.get('token');

  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  const campaignsAndPosthogHandler = (userPost: any, id: any) => {
    if (userPost?.userId || id) {
      trigger(userPost?.userId || id)
        .then((result) => {
          // intentionally added console log
          console.log('result?.data?.data', result?.data?.data);
          if (result?.data?.data) {
            dispatch(setCampaigns(result?.data?.data));
          }
        })
        .catch((error) => {
          console.error('Network Error:', error);
        });
    }

    if (
      window.flutter_inappwebview &&
      window.niyo_initial_redirect &&
      typeof window.niyo_initial_redirect === 'function'
    ) {
      window.niyo_initial_redirect();
    }
  };

  useEffect(() => {
    const initAsync = async () => {
      console.log('Inititalization in progress');
      if (!window.flutter_inappwebview && !window.niyo_user_login) {
        await initializeWindowFunctions();
        console.log('already initialized so skipping');
      }
      if (
        window.flutter_inappwebview &&
        window.niyo_user_login &&
        typeof window.niyo_user_login === 'function'
      ) {
        console.log('login is happening');
        window
          .niyo_user_login()
          .then((res) => {
            console.log('called user login!!!');
            const userObj = parseObject(res);
            userLogin(userObj)
              .then((userRes: any) => {
                if (isTestEmailForProd(userRes?.data?.email)) {
                  dispatch(setSmbFeat(true));
                  dispatch(setStudentFareFeat(true));
                  //Temporarily added for testing. Once done, we will remove vConsole.
                  const vConsole = new VConsole();
                }
                campaignsAndPosthogHandler(userRes?.data, userObj?.id);
                if (window?.niyo_deviceInfo && typeof window?.niyo_deviceInfo === 'function') {
                  window.niyo_deviceInfo().then((res) => {
                    console.log('niyo_deviceInfo', res);
                    const resParse = parseObject(res);
                    dispatch(setDeviceInfo(resParse));
                    console.log('niyo_deviceInfo->resParse', resParse);
                  });
                }
                setIsInitialized(true);
              })
              .catch((error) => {
                setIsInitialized(true);
              })
              .finally(() => {
                setIsInitialized(true);
              });
          })
          .catch((error) => {
            setIsInitialized(true);
          });
      } else {
        if (token) {
          const req = {
            id: '123456778',
            username: 'Vishal Bhimani',
            mobile: '9558611101',
            email: 'arjunbharti774@gmail.com',
            ...(token ? { token: token } : {}),
          };
          await userLogin(req).then((userRes: any) => {
            if (isTestEmailForProd(userRes?.data?.email)) {
              dispatch(setSmbFeat(true));
              dispatch(setStudentFareFeat(true));
              const vConsole = new VConsole();
              //Temporarily added for testing. Once done, we will remove vConsole.
            }
            campaignsAndPosthogHandler(userRes?.data, req?.id);
            setIsInitialized(true);
          });
          setIsInitialized(true);
        } else {
          setIsInitialized(true);
        }
      }
    };
    initAsync();
  }, []);

  return isInitialized;
};

export const InitializationWrapper: React.FC<InitializationWrapperProps> = ({ children }) => {
  const isInitialized = useInitialization();

  if (!isInitialized) {
    console.log('loader is loading');
    return (
      <Box sx={{ height: '100vh' }}>
        <OALoading />
      </Box>
    );
  }

  return <>{children}</>;
};

function App() {
  useEffect(() => {
    console.log('reinitialize');
    initializeWindowFunctions();
    return () => {
      cleanupWindowFunctions();
    };
  }, []);

  useEffect(() => {
    // Function to handle the context menu event
    const handleContextMenu = (event: any) => {
      event.preventDefault(); // Prevents the default context menu
    };

    // Add event listener for 'contextmenu', which includes right-clicks and long-presses
    document.addEventListener('contextmenu', handleContextMenu);

    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <InitializationWrapper>
            <ThemeProvider theme={theme}>
              <Suspense>
                <NetworkDetector>
                  <Router>
                    <AppRoutes />
                    <VisibilityObserver />
                  </Router>
                </NetworkDetector>
              </Suspense>
              <OASnackbar />
            </ThemeProvider>
          </InitializationWrapper>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  );
}

export default App;
