import { FC, useEffect, useState } from 'react';
import { Box, Skeleton } from '@mui/material';
import ItemCard from '../ItemCard';
import { useDispatch, useSelector } from '../../../../store';
import {
  addBaggageSelection,
  removeBaggageSelection,
  getSmbData,
} from '../../../../store/slices/flightOrderSlice';
import EmptySmb from '../EmptySmb';
import { usePostHog } from 'posthog-js/react';
import { eventsTracker } from '../../../../utils/ctEventsTracking';

interface BaggagePanelProps {
  baggageData: any;
  selectedSegment: { from: string; to: string };
  updateSmbAction: any;
  totalTravellers: number;
}

interface BaggageInfo {
  price: number;
  baggageCode: string;
  description: string;
  paxTypeList: string[];
  refundable: boolean;
  baggageType: 'CHECKIN_BAGGAGE';
  displayDetail: string;
}

const BaggagePanel: FC<BaggagePanelProps> = ({
  baggageData,
  selectedSegment,
  updateSmbAction,
  totalTravellers,
}) => {
  const posthog = usePostHog();
  const dispatch = useDispatch();
  const smbData = useSelector(getSmbData);
  const baggages = getBaggageByOriginDestination(
    baggageData,
    selectedSegment?.from,
    selectedSegment?.to
  );
  const [startTime, setStartTime] = useState(Date.now());
  useEffect(() => {
    setStartTime(Date.now());
  }, []);
  const handleAddBaggage = (baggage: BaggageInfo) => {
    const selectedCount = getSelectedBaggageCount(baggage.baggageCode);
    if (selectedCount < totalTravellers) {
      const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);
      eventsTracker(
        {
          flowName: 'Flight',
          screenName: 'SMB',
          ctaAction: 'BaggageAdd',
          screenDuration: totalScreenDuration?.toString(),
          otherData: {
            baggageCode: baggage?.baggageCode,
            displayDetail: baggage?.displayDetail,
            price: baggage?.price,
          },
        },
        posthog
      );
      dispatch(
        addBaggageSelection({
          from: selectedSegment.from,
          to: selectedSegment.to,
          baggageDetails: {
            baggageCode: baggage.baggageCode,
            baggageInfo: baggage.description,
            paxIndex: `${selectedCount + 1}`,
            price: baggage.price,
            displayDetail: baggage?.displayDetail,
          },
          totalTravellers,
          apiResponse: baggageData,
        })
      );
    }
  };

  const handleRemoveBaggage = (baggage: BaggageInfo) => {
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);
    eventsTracker(
      {
        flowName: 'Flight',
        screenName: 'SMB',
        ctaAction: 'BaggageRemove',
        screenDuration: totalScreenDuration?.toString(),
        otherData: {
          baggageCode: baggage?.baggageCode,
          displayDetail: baggage?.displayDetail,
          price: baggage?.price,
        },
      },
      posthog
    );
    dispatch(
      removeBaggageSelection({
        from: selectedSegment.from,
        to: selectedSegment.to,
        baggageCode: baggage.baggageCode,
        totalTravellers,
      })
    );
  };

  const getSelectedBaggageCount = (baggageCode: string) => {
    return smbData?.userSelectedJourneyAncillaries?.reduce(
      (count: number, journey: any) =>
        journey.journeyOriginDestinationInfo.from === selectedSegment.from &&
          journey.journeyOriginDestinationInfo.to === selectedSegment.to
          ? count +
          journey.userSelectedBaggageAncillaries.reduce(
            (baggageCount: number, baggage: any) =>
              baggageCount +
              baggage.baggageDetails.filter((b: any) => b.baggageCode === baggageCode).length,
            0
          )
          : count,
      0
    );
  };

  const sortedBaggages = baggages
    ?.slice()
    .sort((a: BaggageInfo, b: BaggageInfo) => a.price - b.price);

  return (
    <Box>
      {!baggages?.length ? (
        <EmptySmb text="Baggage selection is not available" />
      ) : (
        <>
          <Box py="15px" px="16px" height="calc(100vh - 352px)" overflow="scroll">
            {sortedBaggages?.map((baggageItem: any) => {
              const selectedCount = getSelectedBaggageCount(baggageItem.baggageCode);
              const isSelected = selectedCount > 0;
              return (
                <Box mb="15px" key={baggageItem.baggageCode}>
                  <ItemCard
                    cardType="baggage"
                    code={baggageItem.baggageCode}
                    description={baggageItem?.displayDetail ?? baggageItem.description}
                    price={baggageItem.price}
                    paxListType={baggageItem.paxTypeList}
                    refundable={baggageItem.refundable}
                    travellersCount={totalTravellers}
                    onAdd={() => handleAddBaggage(baggageItem)}
                    onRemove={() => handleRemoveBaggage(baggageItem)}
                    isSelected={isSelected}
                    selectedCount={selectedCount}
                  />
                </Box>
              );
            })}
          </Box>
        </>
      )}
    </Box>
  );
};

export default BaggagePanel;

function getBaggageByOriginDestination(baggageData: any, from: string, to: string) {
  const baggageDetails = baggageData?.flatMap(
    (data: any) => data?.baggageAncillary?.baggageDetails
  );
  const matchingBaggage = baggageDetails?.find((baggage: any) => {
    return (
      baggage?.originDestinationInfo?.from === from && baggage?.originDestinationInfo?.to === to
    );
  });

  return matchingBaggage ? matchingBaggage?.baggageInfos : [];
}
