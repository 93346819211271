import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';

export const OATextField = ({ InputProps, ...rest }: TextFieldProps) => {
  const customInputProps = {
    ...InputProps,
    onFocus: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {},
  };

  return <TextField {...rest} variant="filled" autoComplete="off" InputProps={customInputProps} />;
};
