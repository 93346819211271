import React from 'react';
import FilterAndSort from '.';
import FilterAndSortSkeleton from './FilterAndSortSkeleton';

interface FilterAndSortContainerProps {
  isLoading: boolean;
  isUpdatingResults: boolean;
  isResultsLoading: boolean;
  isError: boolean;
  flights: any;
  filterData: any;
  countOfSuppliers: { [key: string]: number };
  countOfStopsOnward: { [key: string]: number };
  countOfAirports: { [key: string]: number };
  countOfDeparture: { [key: string]: number };
  countOfReturnDeparture: { [key: string]: number };
  currentSortTab: number;
  setCurrentSortTab: (value: number) => void;
  setFilterData: (data: any) => void;
  handleSort: (value: string) => void;
  handleClearSort: () => void;
  handleFilter: (filteringData: any) => void;
  handleClearFilter: () => void;
}

const FilterAndSortContainer: React.FC<FilterAndSortContainerProps> = ({
  isLoading,
  isUpdatingResults,
  isResultsLoading,
  isError,
  flights,
  filterData,
  setFilterData,
  handleSort,
  handleClearSort,
  handleFilter,
  handleClearFilter,
  countOfSuppliers,
  countOfStopsOnward,
  countOfAirports,
  countOfDeparture,
  countOfReturnDeparture,
  currentSortTab,
  setCurrentSortTab,
}) => {
  if (isLoading || isResultsLoading) {
    return !isError ? <FilterAndSortSkeleton /> : null;
  }

  if (flights?.data?.results?.onward?.length > 0) {
    return (
      <FilterAndSort
        filterData={filterData}
        setFilterData={setFilterData}
        onClickSort={handleSort}
        onClickClearSort={handleClearSort}
        onClickFilter={handleFilter}
        onClickClearFilter={handleClearFilter}
        flightsCount={countOfSuppliers}
        countOfStops={countOfStopsOnward}
        countOfAirports={countOfAirports}
        countOfDeparture={countOfDeparture}
        countOfReturnDeparture={countOfReturnDeparture}
        currentSortTab={currentSortTab}
        setCurrentSortTab={setCurrentSortTab}
      />
    );
  }

  return null;
};

export default FilterAndSortContainer;
