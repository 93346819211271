import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web-light';
import animationData from './empty.json';

export const OALoadingLottie = () => {
  const lottieContainer = useRef(null);

  useEffect(() => {
    if (lottieContainer.current) {
      const animation = lottie.loadAnimation({
        container: lottieContainer.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      });

      return () => {
        animation.destroy(); // Cleanup animation on component unmount
      };
    }
  }, []);

  return <div ref={lottieContainer} style={{ height: 120, width: 120 }} />;
};
