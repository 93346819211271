import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { register } from './serviceWorkerRegistration';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://547995627463d917d69d04c716b91888@o1418230.ingest.us.sentry.io/4506715220934656',
  debug: false,
  tracesSampleRate: 1.0,
  beforeSend(event) {
    // Check if it's a network error
    if (event.exception && event.exception.values && event.exception.values[0].type === "NetworkError") {
      return null; // Don't send network errors to Sentry
    }
    return event;
  },
  // allowUrls: [/https:\/\/onarrival-flights-22fff08a18cf18464e4a14575bcd6b43\.goniyo\.com\/.*/, /https:\/\/devflights\.onarriv\.io\/.*/],
  integrations: [
    Sentry.browserTracingIntegration(),
    // Or, if you are using react router, use the appropriate integration
    // See docs for support for different versions of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    // Sentry.reactRouterV6BrowserTracingIntegration({
    //   useEffect: React.useEffect,
    //   useLocation,
    //   useNavigationType,
    //   createRoutesFromChildren,
    //   matchRoutes,
    // }),
  ],
  tracePropagationTargets: [
    /https:\/\/global-oacr-29243f8320e378c33f25334d6d68036f\.goniyo\.com\/onearrival-flightbookings\/api\/v1\/.*/
  ],
});

register(); // Call the register function to register the service worker

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
