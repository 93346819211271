import { Box, Card } from '@mui/material';
import { IFlight } from '../../../type';
import AirlineDetail from './AirlineDetail';
import FlightDetail from './FlightDetail';
import FlightLabel from '../FlightLabel';

const FlightCard = ({
  data,
  onClick,
  tripType,
  isInternational,
  isLowestPrice,
  isFastestFlight,
  isFastestAndLowest,
}: {
  data: IFlight;
  onClick: (flight: IFlight) => void;
  tripType: 'R' | 'O';
  isInternational?: boolean;
  isLowestPrice?: boolean;
  isFastestFlight?: boolean;
  isFastestAndLowest?: boolean;
}) => {
  const domesticReturn = tripType === 'R' && !isInternational;
  
  return (
    <Card
      sx={{
        p: '1px',
        borderRadius: domesticReturn ? '0px' : '8px',
        border: domesticReturn ? 0 : '1px solid #DEE2E1',
        bgcolor: domesticReturn ? 'transparent' : '#FDFDFD',
        position: 'relative',
        overflow: 'unset',
        // ...(data?.price?.exclusiveFare && !domesticReturn ? { pt: '15px' } : {}),
      }}
    >
      {/* {data?.price?.exclusiveFare && !domesticReturn && (
        <OAImage
          src="niyo-exclusive-badge.svg"
          alt="exclusive"
          sx={{
            position: 'absolute',
            top: '-3px',
            left: '15px',
          }}
        />
      )} */}
      {isFastestAndLowest ? (
        <>
          <FlightLabel
            bgColor='#215039'
            icon='percent.svg'
            text='Lowest Price'
            customStyles={{ right: '90px' }}
          />
          <FlightLabel
            bgColor='#F0935F'
            icon='fastest.svg'
            text='Fastest'
            customStyles={{ right: '16px' }}
          />
        </>
      ) : (
        <>
          {isLowestPrice && (
            <FlightLabel
              bgColor='#215039'
              icon='percent.svg'
              text='Lowest Price'
            />
          )}
          {isFastestFlight && (
            <FlightLabel
              bgColor='#F0935F'
              icon='fastest.svg'
              text='Fastest'
            />
          )}
        </>
      )}
      <Box onClick={() => onClick(data)}>
        <AirlineDetail data={data} tripType={tripType} isInternational={isInternational} />
        <FlightDetail data={data} tripType={tripType} isInternational={isInternational} />
      </Box>
    </Card>
  );
};

export default FlightCard;
