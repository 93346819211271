import { Box, Divider, Typography } from '@mui/material';
import { formatToINR } from '../../../utils';
import { useSelector } from '../../../store';
import { getFeatureFlag } from '../../../store/slices/featuresSlice';
import { OACoinBanner } from '../../../components';

const RefundBreakup = ({ data, cancellationData }: any) => {
  const { burnCoinsFeat } = useSelector(getFeatureFlag);

  const paymentSummary = data?.data?.paymentSummary;
  const refundSummary = paymentSummary?.refundSummary;
  const additionalInfo = refundSummary?.additionalInfo;
  const refund = cancellationData?.refund;

  const cancellationFee = additionalInfo?.totalRefundCharges ?? refund?.totalRefundCharges;
  const refundToBank = refundSummary?.refundAmountInCash ?? refund?.refundAmountInCash;
  const airlineCharges = additionalInfo?.airLineCharges ?? refund?.airLineCharges;
  const partnerCharges = additionalInfo?.partnerCharges ?? refund?.partnerCharges;
  const totalNonRefundableFeeCharges =
    additionalInfo?.totalNonRefundableFeeCharges ?? refund?.totalNonRefundableFeeCharges;
  const convenienceFeeCharges =
    additionalInfo?.convenienceFeeCharges ?? refund?.convenienceFeeCharges;
  const totalRefundAmount = refundSummary?.totalRefundAmount ?? refund?.totalRefundAmount;
  const refundCoin = refundSummary?.refundCoins ?? refund?.coinsRefundable;

  const seatCharges = additionalInfo?.ancillaryCharges?.seatCharges ?? refund?.ancillaryCharges?.seatCharges;
  const mealCharges = additionalInfo?.ancillaryCharges?.mealCharges ?? refund?.ancillaryCharges?.mealCharges;
  const baggageCharges = additionalInfo?.ancillaryCharges?.baggageCharges ?? refund?.ancillaryCharges?.baggageCharges;

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="body2" fontWeight={500}>
          Amount paid
        </Typography>
        <Typography variant="body2" fontWeight={500}>
          {formatToINR(paymentSummary?.totalAmount)}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" mt="10px">
        <Typography variant="body2" color="text.secondary">
          Paid by {paymentSummary?.paymentMode?.replace(/_/g, ' ')?.toLowerCase()}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {formatToINR(paymentSummary?.amountInCash ?? 0)}
        </Typography>
      </Box>
      {paymentSummary?.coinsRedeemed !== 0 && (
        <Box display="flex" justifyContent="space-between" mt="10px">
          <Typography variant="body2" color="text.secondary">
            Paid by Niyo Global Coins
          </Typography>
          <Typography variant="body2" color="text.secondary">
            (-{paymentSummary?.coinsRedeemed}) {formatToINR(paymentSummary?.amountInCoins ?? 0)}
          </Typography>
        </Box>
      )}
      <Box display="flex" justifyContent="space-between" my="20px">
        <Typography variant="body2" fontWeight={500}>
          Cancellation fee
        </Typography>
        <Typography variant="body2" fontWeight={500} color="error.main">
          {' '}
          -{formatToINR(cancellationFee ?? 0)}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" mb="10px">
        <Typography variant="body2" color="text.secondary">
          Airline fare
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {formatToINR(airlineCharges ?? 0)}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="body2" color="text.secondary">
          Partner charges
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {formatToINR(partnerCharges ?? 0)}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" my="20px">
        <Typography variant="body2" fontWeight={500}>
          Non refundable fee
        </Typography>
        <Typography variant="body2" fontWeight={500} color="error.main">
          {' '}
          -{formatToINR(totalNonRefundableFeeCharges ?? 0)}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="body2" color="text.secondary">
          Convenience Fee
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {formatToINR(convenienceFeeCharges ?? 0)}
        </Typography>
      </Box>
      {seatCharges > 0 && (
        <Box display="flex" justifyContent="space-between">
          <Typography variant="body2" color="text.secondary">
            Seat charges
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {formatToINR(seatCharges)}
          </Typography>
        </Box>
      )}
      {mealCharges > 0 && (
        <Box display="flex" justifyContent="space-between">
          <Typography variant="body2" color="text.secondary">
            Meal charges
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {formatToINR(mealCharges)}
          </Typography>
        </Box>
      )}
      {baggageCharges > 0 && (
        <Box display="flex" justifyContent="space-between">
          <Typography variant="body2" color="text.secondary">
            Baggage charges
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {formatToINR(baggageCharges)}
          </Typography>
        </Box>
      )}
      <Divider sx={{ borderBottomWidth: '1px', borderColor: '#DEE2E1', my: '20px' }} />
      <Box mt="20px" mb="16px" display="flex" justifyContent="space-between">
        <Typography fontWeight={600}>Total refund</Typography>
        <Typography fontWeight={600}>{formatToINR(totalRefundAmount ?? 0)}</Typography>
      </Box>
      {burnCoinsFeat && (
        <Box display="flex" mb="16px" justifyContent="space-between">
          <Typography variant="body2" color="text.secondary">
            Refunded to Bank A/C
          </Typography>
          <Typography variant="body2">{formatToINR(refundToBank ?? 0)}</Typography>
        </Box>
      )}
      <OACoinBanner
        title="Coins refunded back"
        subTitle={refundCoin}
        isCurrency={false}
        color={refundCoin > 0 ? 'yellow' : 'error'}
        sx={{ mb: '16px' }}
      />
    </>
  );
};

export default RefundBreakup;
