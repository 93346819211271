import { FC, useState } from 'react';
import { Box, Container, Divider, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { OAImage, OASwipeableDrawer } from '../../../components';

interface CustomSectionProps {
  icon: string;
  title: string;
  description: string;
  onClick?: () => void;
}

const CustomSection: FC<CustomSectionProps> = ({ icon, title, description, onClick }) => (
  <Container onClick={onClick}>
    <Box display="flex" justifyContent="space-between">
      <Box display="flex" alignItems="start">
        <OAImage src={icon} folder="icons" alt="support" sx={{ mt: '4px' }} />

        <Box display="flex" flexDirection="column" sx={{ ml: '10px' }}>
          <Typography variant="body2" sx={{ mb: '4px' }}>
            {title}
          </Typography>
          <Typography variant="body3" sx={{ color: 'text.secondary', maxWidth: '85%' }}>
            {description}
          </Typography>
        </Box>
      </Box>
      <ChevronRightIcon sx={{ color: 'text.secondary' }} />
    </Box>
  </Container>
);
const ContactSupport = ({ onClick, bookingId }: { onClick: any; bookingId: any }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleDrawer = () => setIsOpen((prev) => !prev);

  const handleEmailClick = () => {
    if (
      window?.flutter_inappwebview &&
      window.niyo_open_url &&
      typeof window.niyo_open_url === 'function'
    ) {
      const strEmail = `mailto:traveldesk@goniyo.com?subject=${bookingId} : Need help with my flight booking`;
      window.niyo_open_url(strEmail);
    } else {
      console.log('only allowed in mobile');
    }
  };

  return (
    <>
      <Container sx={{ mb: '20px' }} onClick={toggleDrawer}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            Contact support
          </Typography>
          <ChevronRightIcon />
        </Box>
      </Container>
      <OASwipeableDrawer
        title="Contact support"
        open={isOpen}
        isContainer={false}
        onOpen={toggleDrawer}
        onClose={toggleDrawer}
      >
        <CustomSection
          onClick={onClick}
          icon="chatbot.svg"
          title="Chat support"
          description="Searching for something? We're here for you. Feel free to ask!"
        />
        <Divider sx={{ borderBottomWidth: '4px', borderColor: '#F4F6F5', my: '20px' }} />
        <CustomSection
          onClick={handleEmailClick}
          icon="email-support.svg"
          title="Email"
          description="traveldesk@goniyo.com"
        />
        <Box sx={{ height: '24px' }}></Box>
      </OASwipeableDrawer>
    </>
  );
};

export default ContactSupport;
