import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface OADialogProps {
  open: boolean;
  content: any;
  handleClose: () => void;
}

const OADialog: React.FC<OADialogProps> = ({ open, content, handleClose }) => {
  if (!open) return null;

  return (
    <div style={styles.overlay}>
      <div style={styles.dialog}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            bgcolor: '#FFF',
            position: 'absolute',
            borderRadius: '50%',
            height: '35px',
            width: '35px',
            right: '10px',
            top: '10px',
            zIndex: 1302,
          }}
          onClick={handleClose}
        >
          <CloseIcon sx={{ color: '#5A6068', height: '20px', width: '20px' }} />
        </Box>
        {content}
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: 'fixed' as 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1300,
  },
  dialog: {
    position: 'relative' as 'relative',
    backgroundColor: '#FFF',
    borderRadius: '20px',
    margin: '20px',
    zIndex: 1301,
  },
};

export default OADialog;
