import React, { useEffect, useRef, useState } from 'react';
import { Box, Card, Divider, Tab, Tabs, Typography } from '@mui/material';
import { formatNumberInINR } from '../../utils';
import CancellationChargesInfo from '../../pages/MyBooking/BookingStatus/CancellationChargesInfo';
import DateChangeInfo from '../../pages/MyBooking/BookingStatus/DateChangeInfo';
import { theme } from '../../config';
import OAFlightRoute from '../OAFlightRoute';
import { OAImage } from '../OAImage';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  maxHeight: any;
}

// Use React.forwardRef to forward the ref properly with typing
const CustomTabPanel = React.forwardRef<HTMLDivElement, TabPanelProps>((props, ref) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      ref={ref} // This is where the ref is used
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ minHeight: `${props.maxHeight}px` }} // Ensure you're passing maxHeight correctly
      {...other}
    >
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
});

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const BaggageAndCancellation = ({
  baggageInfo,
  cancellationInfo,
  departure,
  arrival,
  onHeightChange,
}: any) => {
  const [value, setValue] = useState<number>(1);
  const [maxHeight, setMaxHeight] = useState<number>(0);
  const tabRefs: any = useRef([]);

  // Dynamically calculate and update the max height of tabs content
  useEffect(() => {
    let maxContentHeight = 0;
    tabRefs.current.forEach((ref: any) => {
      if (ref && ref.offsetHeight > maxContentHeight) {
        maxContentHeight = ref.offsetHeight;
      }
    });
    setMaxHeight(maxContentHeight);
  }, [value]); // Depend on value to re-calculate when tabs change

  // Ensure the refs array can hold all tab panels
  useEffect(() => {
    tabRefs.current = tabRefs.current.slice(0, 3);
    setValue(0);
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const today = new Date().toISOString();

  const groupedByTravellerType =
    cancellationInfo?.cancellationFareRule &&
    cancellationInfo?.cancellationFareRule?.reduce((acc: Record<string, any[]>, item: any) => {
      if (item?.start > today || item?.end > today) {
        const type = item?.travellerType;
        if (!acc[type]) {
          acc[type] = [];
        }
        acc[type]?.push(item);
      }
      return acc;
    }, {});

  const groupedByChargeType = cancellationInfo?.amendmentFareRule?.reduce(
    (acc: Record<string, any[]>, item: any) => {
      if (item?.type === 'AMEND_SAME_FARE' && (item?.start >= today || item?.end >= today)) {
        const type = item?.travellerType;
        return {
          ...acc,
          [type]: [...(acc[type] || []), item],
        };
      }
      return acc;
    },
    {}
  );
  
  return (
    <Box sx={{ width: '100%' }}>
      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
        <Tab
          label="Baggage"
          {...a11yProps(0)}
          sx={{
            textTransform: 'none',
            flexGrow: 1,
          }}
        />
        <Tab
          label="Cancellation"
          {...a11yProps(1)}
          sx={{
            textTransform: 'none',
            flexGrow: 1,
          }}
        />
        <Tab
          label="Date change"
          {...a11yProps(2)}
          sx={{
            textTransform: 'none',
            flexGrow: 1,
          }}
        />
      </Tabs>
      <Box bgcolor="#F4F6F5" p="10px 16px">
        <OAFlightRoute 
          from={departure}
          to={arrival}
          icon='flight-take-off.svg'
        />
      </Box>
      <Box px="16px">
        <CustomTabPanel
          value={value}
          index={0}
          ref={(el) => (tabRefs.current[0] = el)}
          maxHeight={maxHeight}
        >
          <Card
            sx={{ padding: '16px', display: 'flex', flexDirection: 'column', borderRadius: '8px' }}
          >
            <Box display="flex" justifyContent="space-between" mb="15px">
              <Typography variant="body2">Cabin baggage</Typography>
              <Typography variant="body2" fontWeight={500}>
                {baggageInfo?.cabin}
              </Typography>
            </Box>
            <Divider sx={{ width: '100%', borderColor: '#DEE2E1', mb: '15px' }} />
            <Box display="flex" justifyContent="space-between">
              <Typography variant="body2">Check-in baggage</Typography>
              <Typography variant="body2" fontWeight={500}>
                {baggageInfo?.checkIn} (1 piece)
              </Typography>
            </Box>
          </Card>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={1}
          ref={(el) => (tabRefs.current[1] = el)}
          maxHeight={maxHeight}
        >
          {groupedByTravellerType ? (
            <CancellationChargesInfo data={groupedByTravellerType} />
          ) : (
            <Card sx={{ padding: '16px', borderRadius: '8px' }}>
              {cancellationInfo?.cancellationAndAmendCharges?.map((item: any) => (
                <>
                  <Box display="flex" justifyContent="space-between" mb="16px">
                    <Typography textTransform="capitalize" variant="body2">
                      {item?.display}
                    </Typography>
                    <Typography variant="body2" fontWeight={500}>
                      ₹{formatNumberInINR(item?.cancel ?? 0)}
                    </Typography>
                  </Box>
                  <Divider sx={{ width: '100%', borderColor: '#DEE2E1', mb: '15px' }} />
                </>
              ))}
              {cancellationInfo?.timeBasedMiniRule?.cancel?.map((item: any) => (
                <>
                  <Box display="flex" justifyContent="space-between" mb="16px">
                    <Typography
                      textTransform="capitalize"
                      variant="body2"
                      color={theme.palette.grey[800]}
                    >
                      {item?.display}
                    </Typography>
                    <Typography variant="body2" fontWeight={500} color="text.secondary">
                      {item?.charges?.display}
                    </Typography>
                  </Box>
                  <Divider sx={{ width: '100%', borderColor: '#DEE2E1', mb: '15px' }} />
                </>
              ))}
              <Box mt="16px">
                <Typography variant="body3" color="gray">
                  {cancellationInfo?.note}
                </Typography>
              </Box>
            </Card>
          )}
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={2}
          ref={(el) => (tabRefs.current[2] = el)}
          maxHeight={maxHeight}
        >
          {groupedByChargeType ? (
            <DateChangeInfo data={groupedByChargeType} />
          ) : (
            <Card sx={{ padding: '16px', borderRadius: '8px' }}>
              {cancellationInfo?.cancellationAndAmendCharges?.map((item: any) => (
                <>
                  <Box display="flex" justifyContent="space-between" mb="16px">
                    <Typography textTransform="capitalize" variant="body2">
                      {item?.display}
                    </Typography>
                    <Typography variant="body2" fontWeight={500}>
                      ₹{formatNumberInINR(item?.amend ?? 0)}
                    </Typography>
                  </Box>
                  <Divider sx={{ width: '100%', borderColor: '#DEE2E1', mb: '15px' }} />
                </>
              ))}
              <Box mt="16px">
                <Typography variant="body3" color="gray">
                  {cancellationInfo?.note}
                </Typography>
              </Box>
            </Card>
          )}
        </CustomTabPanel>
      </Box>
    </Box>
  );
};

export default BaggageAndCancellation;
