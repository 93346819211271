import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { OALoadingLottie } from '../OALoadingLottie';

interface OALoadingProps {
  title?: string;
  subTitle?: string;
}
export const OALoading: FC<OALoadingProps> = ({ title, subTitle }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        textAlign: 'center',
      }}
    >
      <OALoadingLottie />
      {title && (
        <Typography variant="body2" sx={{ fontWeight: 600, mt: '8px' }}>
          {title}
        </Typography>
      )}
      {subTitle && (
        <Typography variant="body3" sx={{ color: 'text.secondary', mt: '2px' }}>
          {subTitle}
        </Typography>
      )}
    </Box>
  );
};
