import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import TravellerCount from './TravellerCount';
import { OAButton, OAImage, OARadioGroup, OASwipeableDrawer, OATextField } from '../../components';
import { flightClassOptions } from '../../constants';
import { useDispatch } from '../../store';
import { capitalizeFirstLetter } from '../../utils';
import { setMessage } from '../../store/slices/snackbarSlice';

const labelContent = (
  <Box display="flex" alignContent="center">
    <OAImage src="person.svg" folder="icons" alt="person" sx={{ mr: '10px' }} />
    Travellers & Class
  </Box>
);

const SelectTravellers = ({ localTravelDetail, onSelect }: any) => {
  const dispatch = useDispatch();

  const [selectedClass, setSelectedClass] = useState<string>(
    localTravelDetail?.cabinType ?? 'Economy'
  );
  const [adultCount, setAdultCount] = useState<number>(
    localTravelDetail?.travellerCount?.adult ?? 1
  );
  const [childCount, setChildCount] = useState<number>(
    localTravelDetail?.travellerCount?.child ?? 0
  );
  const [infantCount, setInfantCount] = useState<number>(
    localTravelDetail?.travellerCount?.infant ?? 0
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleDrawer = useCallback(() => {
    setIsOpen((prevState) => !prevState);
  }, []);

  const handleSaveChanges = () => {
    // Dispatch the local state values to the global state when "Done" button is clicked
    onSelect({
      ...localTravelDetail,
      cabinType: selectedClass,
      travellerCount: {
        adult: adultCount,
        child: childCount,
        infant: infantCount,
      },
    });
    toggleDrawer();
  };

  const handleAdultsChange = (count: number) => {
    setAdultCount(count);
  };

  const handleChildrenChange = (count: number) => {
    setChildCount(count);
  };

  const handleInfantChange = (count: number) => {
    setInfantCount(count);
  };

  const maxAdultCount = 9 - childCount - infantCount;
  const maxChildCount = 9 - adultCount - infantCount;
  const maxInfantCount = Math.min(9 - adultCount - childCount, adultCount);

  const handleSelectClass = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedClass(event.target.value);
  };

  const formattedTravelDetail = React.useMemo(() => {
    const detailsArray = [
      localTravelDetail?.travellerCount?.adult
        ? `${localTravelDetail?.travellerCount?.adult} Adult`
        : '',
      localTravelDetail?.travellerCount?.child
        ? `${localTravelDetail?.travellerCount?.child} Child`
        : '',
      localTravelDetail?.travellerCount?.infant
        ? `${localTravelDetail?.travellerCount?.infant} Infant`
        : '',
    ]
      .filter(Boolean)
      .join(', ');

    return `${detailsArray}${
      localTravelDetail?.cabinType
        ? ` ・ ${capitalizeFirstLetter(localTravelDetail?.cabinType)}`
        : ''
    }`.trim();
  }, [localTravelDetail]);

  // Effect hook to reset local state when drawer is opened
  useEffect(() => {
    if (isOpen) {
      setSelectedClass(localTravelDetail?.cabinType ?? 'Economy');
      setAdultCount(localTravelDetail?.travellerCount?.adult ?? 1);
      setChildCount(localTravelDetail?.travellerCount?.child ?? 0);
      setInfantCount(localTravelDetail?.travellerCount?.infant ?? 0);
    }
  }, [isOpen, localTravelDetail]);

  return (
    <>
      <OATextField
        label={labelContent}
        variant="filled"
        fullWidth
        onClick={toggleDrawer}
        InputProps={{
          readOnly: true,
        }}
        focused={isOpen}
        value={formattedTravelDetail}
      />
      <OASwipeableDrawer
        title="Select travellers"
        open={isOpen}
        onOpen={toggleDrawer}
        onClose={toggleDrawer}
      >
        <TravellerCount
          label="Adults"
          onChange={handleAdultsChange}
          countInit={adultCount}
          maxCount={maxAdultCount}
          totalPax={adultCount + childCount + infantCount}
        />
        <TravellerCount
          label="Children"
          onChange={handleChildrenChange}
          countInit={childCount}
          maxCount={maxChildCount}
          totalPax={adultCount + childCount + infantCount}
        />
        <TravellerCount
          label="Infant"
          onChange={handleInfantChange}
          countInit={infantCount}
          maxCount={maxInfantCount}
          totalPax={adultCount + childCount + infantCount}
        />
        <Box sx={{ mt: '30px' }}>
          <OARadioGroup
            sx={{
              fontWeight: 600,
              fontSize: '16px',
              color: 'black',
              mb: '10px',
              '&.Mui-focused': {
                color: 'text.primary',
              },
            }}
            label="Select travel class"
            options={flightClassOptions}
            value={selectedClass}
            onChange={handleSelectClass}
          />
        </Box>
        <OAButton
          variant="contained"
          color="secondary"
          sx={{
            my: '24px',
            width: '100%',
            borderRadius: '42px',
            paddingY: '12px',
            background: '#141126',
          }}
          onClick={handleSaveChanges}
        >
          Done
        </OAButton>
      </OASwipeableDrawer>
    </>
  );
};

export default SelectTravellers;
