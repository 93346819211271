import React, { useRef } from 'react';
import { Box, Container, Skeleton } from '@mui/material';
import { ISegment } from '../../../../type';
import { OASwipeableDrawer } from '../../../../components';
import CoinBannerSection from './CoinBannerSection';
import { getEarnCoin } from '../../../../utils';
import OASwitchComponent from '../../../../components/OASwitchComponent';
import FlightSegments from './FlightSegments';
import FlightPriceSummary from './FlightPriceSummary';

interface ConfirmFlightDrawerProps {
  isOpen: boolean;
  toggleDrawer: () => void;
  isLoading: boolean;
  selectedFlight: any;
  travelDetail: any;
  tripType: string;
  tabValue: number;
  onwardSegments: ISegment[];
  returnSegments: ISegment[];
  isConfirmFlight: boolean;
  handleCreateFlightOrder: () => void;
  flights: any;
  isInternational: boolean;
  uniqueAirlinesOnward: any;
  uniqueAirlinesReturn: any;
  onwardPrice: string;
  returnPrice: string;
  totalPrice: number;
  isCoinsApplied: boolean;
  coins: number;
  switchToggleLabels: string[];
  setTabValue: any;
  miniRuleData: any;
  isMiniRuleLoading?: boolean;
}

const ConfirmFlightDrawer: React.FC<ConfirmFlightDrawerProps> = ({
  isOpen,
  toggleDrawer,
  isLoading,
  selectedFlight,
  travelDetail,
  tripType,
  tabValue,
  onwardSegments,
  returnSegments,
  isConfirmFlight,
  handleCreateFlightOrder,
  flights,
  isInternational,
  uniqueAirlinesOnward,
  uniqueAirlinesReturn,
  onwardPrice,
  returnPrice,
  totalPrice,
  isCoinsApplied,
  coins,
  switchToggleLabels,
  setTabValue,
  miniRuleData,
  isMiniRuleLoading,
}) => {
  const scrollContainerRef = useRef<any>(null);

  const handleSwitchChange = (newValue: any) => {
    setTabValue(newValue);
  };
  const coinsToBeEarned = getEarnCoin(
    (selectedFlight?.onward?.price?.value ?? 0) + (selectedFlight?.return?.price?.value ?? 0)
  );

  return (
    <OASwipeableDrawer
      title="Confirm flight"
      open={isOpen}
      onClose={toggleDrawer}
      onOpen={toggleDrawer}
      isContainer={false}
      maxHeight={90}
      transitionDuration={200}
    >
      {coinsToBeEarned > 0 && (
        <CoinBannerSection isLoading={isLoading} coinAmount={coinsToBeEarned} />
      )}
      {selectedFlight?.id || selectedFlight?.onward ? (
        <>
          {tripType === 'R' && (
            <Box
              mb="7px"
              pb="8px"
              position="sticky"
              top={coinsToBeEarned ? '140px' : '90px'}
              px="15px"
              bgcolor="white"
              zIndex="10"
            >
              <OASwitchComponent
                value={tabValue}
                labels={switchToggleLabels}
                onChange={handleSwitchChange}
              />
            </Box>
          )}
          <Box ref={scrollContainerRef}>
            {tabValue === 0 && (
              <FlightSegments
                segments={onwardSegments}
                travelDetail={travelDetail}
                isConfirmFlight={isConfirmFlight}
                handleCreateFlightOrder={handleCreateFlightOrder}
                searchId={flights?.data?.searchId}
                selectedFlight={selectedFlight}
                isInternational={isInternational}
                direction="onward"
                miniRuleData={miniRuleData}
                isMiniRuleLoading={isMiniRuleLoading}
              />
            )}
            {tabValue === 1 && returnSegments?.length > 0 && (
              <FlightSegments
                segments={returnSegments}
                travelDetail={travelDetail}
                isConfirmFlight={isConfirmFlight}
                handleCreateFlightOrder={handleCreateFlightOrder}
                searchId={flights?.data?.searchId}
                selectedFlight={selectedFlight}
                isInternational={isInternational}
                direction="return"
                miniRuleData={miniRuleData}
                isMiniRuleLoading={isMiniRuleLoading}
              />
            )}
          </Box>
          <FlightPriceSummary
            returnSegments={returnSegments}
            isInternational={isInternational}
            uniqueAirlinesOnward={uniqueAirlinesOnward}
            uniqueAirlinesReturn={uniqueAirlinesReturn}
            onwardPrice={onwardPrice}
            returnPrice={returnPrice}
            totalPrice={totalPrice}
            isCoinsApplied={isCoinsApplied}
            coins={coins}
            handleCreateFlightOrder={handleCreateFlightOrder}
          />
        </>
      ) : (
        <>
          <Container>
            <Skeleton variant="rectangular" width="100%" height="32px" sx={{ mb: '16px' }} />
            <Skeleton variant="rectangular" width="100%" height="300px" sx={{ mb: '10px' }} />
          </Container>
          <Skeleton variant="rectangular" width="100%" height="91px" />
        </>
      )}
    </OASwipeableDrawer>
  );
};

export default ConfirmFlightDrawer;
