import { Box } from '@mui/material';
import { OAImage } from '../../../components';

const LabelContent = ({ label }: { label: string }) => (
  <Box display="flex" alignItems="center">
    <OAImage src="insert-invitation.svg" folder="icons" alt="calendar" sx={{ mr: '10px' }} />
    {label}
  </Box>
);

export default LabelContent;
