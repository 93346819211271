import { FC } from 'react';
import { OATextField } from '../../../components';
import LabelContent from './LabelContent';
import { getFormatedDate } from '../../../utils';

interface DateInputFieldProps {
  label: string;
  value?: any;
  focused?: boolean;
  onClick: (value: string) => void;
}
const DateInputField: FC<DateInputFieldProps> = ({ label, value, focused, onClick }) => {
  const capitalizeLabel = label ? label.charAt(0).toUpperCase() + label.slice(1).toLowerCase() : '';
  const formattedDate = value ? getFormatedDate(value, 'dd MMM yyyy') : '';

  return (
    <OATextField
      label={<LabelContent label={capitalizeLabel} />}
      variant="filled"
      fullWidth
      InputProps={{ readOnly: true }}
      value={formattedDate ?? ''}
      onClick={() => onClick(label)}
      focused={focused}
    />
  );
};

export default DateInputField;
