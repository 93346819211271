import { Box, Card, Container, Divider, Skeleton, Typography } from '@mui/material';
import { FC, useState } from 'react';
import AirlineDetailAndDate from './AirlineDetailAndDate';
import FlightTimeline from './FlightTimeline';
import FlightInfo from './FlightInfo';
import { OASwipeableDrawer } from '..';
import { IFlight, ILayovers } from '../../type';
import BaggageAndCancellation from './BaggageAndCancellation';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LayoverInfo from './LayoverInfo';
import { useSelector } from 'react-redux';
import { getFlightOrderState } from '../../store/slices/flightOrderSlice';
import FloatingLayover from './FloatingLayover';

interface OAFlightSummaryProps {
  data?: any;
  isConform?: boolean;
  onClick?: () => void;
  loading?: boolean;
  searchId?: string;
  isConfirmItinerary?: boolean;
  currentOrderData?: any;
  tripType?: 'O' | 'R';
  layovers?: any;
  selectedFlight?: any;
  isInternational?: boolean;
  direction?: any;
  miniRuleData?: any;
  isMiniRuleLoading?: boolean;
}
export const OAFlightSummary: FC<OAFlightSummaryProps> = ({
  data,
  isConform = false,
  onClick,
  loading = false,
  searchId,
  isConfirmItinerary = false,
  currentOrderData,
  tripType,
  layovers,
  selectedFlight,
  isInternational,
  direction,
  miniRuleData,
  isMiniRuleLoading,
}) => {
  const [isInfoDrawerOpen, setIsInfoDrawerOpen] = useState<boolean>(false);
  const { flightData } = useSelector(getFlightOrderState);
  const [activeSegmentId, setActiveSegmentId] = useState<any>(null);
  const toggleInfoDrawer = (segmentId = null) => {
    setIsInfoDrawerOpen(!isInfoDrawerOpen);
    setActiveSegmentId(segmentId);
  };

  const activeSegment = data?.id === activeSegmentId;
  const segLayovers = layovers?.find(
    (layover: ILayovers) => layover?.exists && layover?.startSegmentId === data?.id
  );
  let cabinWeight, checkInWeight;

  if (selectedFlight?.flights) {
    const baggageInfo = selectedFlight?.flights?.flatMap((flight: IFlight) =>
      flight?.segments?.filter((s) => s?.segmentId === data?.segmentId)
    )[0]?.baggageInfo?.baggages?.[0];

    cabinWeight = baggageInfo?.cabin;
    checkInWeight = baggageInfo?.checkIn;
  }

  return (
    <Container sx={{ position: 'relative' }}>
      <Card>
        <AirlineDetailAndDate data={data} loading={data ? false : true} />
        <Divider sx={{ mt: '12px' }} />

        <FlightTimeline
          segment={data}
          loading={data ? false : true}
          isConfirmItinerary={isConfirmItinerary}
          totalSegments={data?.segments?.length}
        />
        {isConform && searchId
          ? // <FlightInfo
            //   isInternational={isInternational}
            //   tripType={tripType}
            //   selected={selectedFlight}
            //   searchId={searchId}
            //   segmentId={data?.id}
            //   direction={direction}
            //   miniRuleData={miniRuleData}
            //   isMiniRuleLoading={isMiniRuleLoading}
            // />
            null
          : !isConfirmItinerary && (
              <Box display="flex" flexDirection="column">
                <Divider sx={{ mt: '16px', mb: '10px' }} />
                <Typography
                  variant="body3"
                  sx={{ bgcolor: '#f4f6f5' }}
                  p="5px 8px"
                  borderRadius="4px"
                >
                  Cabin: {cabinWeight} / pax • Check-in: {checkInWeight} / pax
                </Typography>
                <Typography
                  variant="body3"
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  sx={{
                    bgcolor: '#f4f6f5',
                    borderRadius: '4px',
                    p: '5px 8px',
                    mt: '10px',
                  }}
                  onClick={() => toggleInfoDrawer(data?.id)}
                >
                  Baggage & Cancellation policy
                  <ChevronRightIcon />
                </Typography>
              </Box>
            )}
      </Card>
      {segLayovers?.exists && !isConfirmItinerary && <LayoverInfo layover={segLayovers} />}
      {segLayovers?.exists && isConfirmItinerary && <FloatingLayover segLayovers={segLayovers} />}
      <OASwipeableDrawer
        title="Baggage & Cancellation Policy"
        open={isInfoDrawerOpen}
        onClose={toggleInfoDrawer}
        onOpen={toggleInfoDrawer}
        isContainer={false}
      >
        {activeSegment ? (
          <BaggageAndCancellation
            baggageInfo={
              selectedFlight?.flights?.flatMap((flight: IFlight) =>
                flight?.segments?.filter((segment) => segment?.segmentId === data?.segmentId)
              )[0]?.baggageInfo?.baggages?.[0]
            }
            cancellationInfo={
              currentOrderData?.flights?.[0]?.minirule ??
              selectedFlight?.flights?.find((flight: IFlight) =>
                flight?.segments?.some((s) => s?.segmentId === data?.segmentId)
              )?.minirule
            }
            arrival={data?.arrival?.airport?.city}
            departure={data?.departure?.airport?.city}
          />
        ) : (
          <Box px="16px">
            <Skeleton height="70px" />
            <Skeleton height="70px" />
            <Skeleton height="70px" />
            <Skeleton height="70px" />
          </Box>
        )}
      </OASwipeableDrawer>
    </Container>
  );
};
