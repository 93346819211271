import { FC, SyntheticEvent, useEffect } from 'react';
import { Fade, IconButton, Snackbar, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { clearMessage } from '../../store/slices/snackbarSlice';
interface SnackbarProps {
  isOpen?: boolean;
  onClose?: () => void;
  message?: string;
}

export const OASnackbar: FC<SnackbarProps> = ({ isOpen, onClose, message }) => {
  const snackbarMessage = useSelector((state: any) => state.snackbar.message);
  const dispatch = useDispatch();

  const handleClose: (event: SyntheticEvent | Event, reason?: string) => void = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(clearMessage());
  };

  // Force close after 7 seconds
  useEffect(() => {
    if (snackbarMessage) {
      const timer = setTimeout(() => {
        handleClose({} as Event, 'timeout')
      }, 7000)

      return () => clearTimeout(timer)
    }
  }, [snackbarMessage])

  return (
    <Snackbar
      open={!!snackbarMessage}
      autoHideDuration={3000}
      onClose={handleClose}
      message={
        <Typography variant="body2" sx={{ color: 'primary.contrastText' }}>
          {snackbarMessage}
        </Typography>
      }
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      TransitionComponent={Fade}
      sx={{
        bottom: 84, // Also adjust the bottom margin to ensure consistency
      }}
      action={
        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
          <Typography variant="body2" sx={{ fontWeight: 500, color: 'primary.main' }}>
            Okay
          </Typography>
        </IconButton>
      }
    ></Snackbar>
  );
};
