import React from "react";
import { Tabs, Tab } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomTabs = styled(Tabs)(({ theme }) => ({
  overflow: "hidden",
  position: "relative",
  backgroundColor: "#FFFFFF",
  borderRadius: "50px",
  border: "1px solid #E2E2E2",
  padding: "4px",
  minHeight: "32px !important",
  "& .MuiTabs-indicator": {
    height: "100%",
    borderRadius: "50px",
    transition: "left 0.2s linear", // Shorter and linear transition
  },
  "& .MuiTouchRipple-root": {
    display: "none",
  },
}));

const CustomTab = styled(Tab)({
  textTransform: "none",
  flex: "1",
  zIndex: 1,
  transition: "color 0.2s linear", // Shorter and linear transition
  minHeight: "32px !important",
  paddingTop: "6px !important",
  paddingBottom: "6px !important",
  background: "transparent",
  "&.Mui-selected": {
    color: "white",
    borderRadius: "50px",
  },
  "&:first-of-type": {
    borderRadius: "50px",
  },
  "&:last-of-type": {
    borderRadius: "50px",
  },
});

const OASwitchComponent = React.memo(({ value, onChange, labels = ["Option 1", "Option 2"], ...props }: any) => {
  const handleTabChange = React.useCallback((event: React.ChangeEvent<{}>, newValue: number) => {
    onChange(newValue);
  }, [onChange]); // useCallback to memoize callback
  
  return (
    <CustomTabs
      value={value}
      onChange={handleTabChange}
      aria-label="custom switch"
      variant="fullWidth"
      {...props}
    >
      {labels.map((label: string, index:number) => (
        <CustomTab key={index} label={label} />
      ))}
    </CustomTabs>
  );
});

export default OASwitchComponent;
